import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';

const ConfettiComponent = () => {
  const randomInRange = (min, max) => {
    return Math.random() * (max - min) + min;
  };
  
  const origin = { x: 0.4, y: 0.7 };

  useEffect(() => {
    // Function to trigger confetti
    const fireConfetti = () => {
      confetti({
        particleCount: 200,
        spread: 100,
        origin: origin,
      });
    };

    // Fire confetti immediately
    fireConfetti();

    // Set up an interval to keep firing confetti
    const intervalId = setInterval(fireConfetti, 1000); // Fires every 1 second

    // Set a timeout to clear the interval after 3 seconds
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 2000); // Stops after 3 seconds

    // Clean up interval and timeout on component unmount
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []); // Empty dependency array to run this effect only once on mount

  return null; // No visible component, just the confetti effect
};

export default ConfettiComponent;