import React, { useState, useEffect } from "react";
import Footer from "../screens/Footer";
import { Grid } from '@mui/material';
import userService from '../Services/userService';
import BackButton from '../components/backButton';
import TopHeader from "../components/TopHeader";
import users from '../resources/assets/Images/icons/users.svg';
import artconnect from '../resources/assets/Images/icons/artconnect.svg';
import modules from '../resources/assets/Images/icons/modules.svg';
import novels from '../resources/assets/Images/icons/novels.svg';
import avgscr from '../resources/assets/Images/icons/avgscr.svg';
import certificates from '../resources/assets/Images/icons/certificates.svg';
import quiz from '../resources/assets/Images/icons/quiz.svg';


import './UserStats.css';

const styles = {
    mainDiv: {
        color: '#000',
        display: 'inline-block',
    },
    container: {
        width: "90%",
        margin: "2% auto",
    },
    heading: {
        fontSize: '1.8rem',
        marginBottom: '1.875rem',
        position: 'relative',
        textAlign: 'center',
        color: 'rgba(255,255,255,0.7)',
    },
    itemContainer: {
        backgroundColor: '#9197A3',
        width: '100%',
        maxWidth: '18.75rem',
        height: '11.25rem',
        borderRadius: '0.9375rem',
        boxShadow: '0.25rem 0.25rem 0.625rem rgba(0,0,0,0.2)',
        color: '#fff',
        margin: '0 auto',
        position: 'relative',
    },
    upperHalf: {
        backgroundColor: '#007bff',
        borderTopLeftRadius: '0.9375rem',
        borderTopRightRadius: '0.9375rem',
        height: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        flexDirection: 'row', // Ensure circle and title are in the same row
    },
    iconAndTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem', // Consistent spacing between circle and title
        padding: 10,
    },
    whiteCircle: {
        width: '2.5rem',
        height: '2.5rem',
        backgroundColor: '#fff',
        borderRadius: '50%',
    },
    lowerHalf: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50%',
    },
    itemTitle: {
        color: '#fff',
        fontWeight: '400',
        fontSize: '1.1rem',
    },
    statValue: {
        fontSize: '3rem',
        color: '#0054A5',
        fontWeight: '600',
        marginBottom: '0.3125rem',
    },
    secondRow: {
        marginTop: '1.875rem',
    },
};

function UsersStats() {
    const [userStats, setUserStats] = useState({});

    useEffect(() => {
        if (Object.keys(userStats).length <= 0) getUserStats();
    }, [userStats]);

    async function getUserStats() {
        // alert("hell")
        await userService.getInstance().getUserStats().then((res) => {
            // let result = JSON.stringify(res);
            console.log(res)
            // let obj = JSON.parse(result);
            // console.log(obj.data);
            // if (obj.code === 200) {
                setUserStats(res);
            // } else {
            //     // Handle error case
            // }
        });
    }

    return (
        <>
            <TopHeader to="/" />
            {/* <BackButton /> */}
            <div className="mainDiv" style={styles.mainDiv}>
                <Grid container alignItems="center" style={styles.container} spacing={2}>
                    <div style={{ margin: '2% auto 0' }}>
                        <button
                            style={{
                                backgroundColor: '#0054A5',
                                boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                color: '#fff',
                                padding: '0.5rem 2.3rem',
                                border: 'none',
                                borderRadius: '0.6rem',
                                fontWeight: '700',
                                fontSize: '1.3rem',
                                margin: '0 auto 1.7rem',
                              }}
                            disabled
                        >
                            USER STATISTICS
                        </button>
                        {/* <a
                        href="\NSF1E"
                        target="_blank"
                            style={{
                                backgroundColor: '#0054A5',
                                boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                color: '#fff',
                                padding: '0.2rem 2.3rem',
                                border: 'none',
                                borderRadius: '0.5rem',
                                fontWeight: '700',
                                fontSize: '1.1rem',
                                margin: '0 auto 1.7rem',
                            }}
                        >
                            English
                        </a>

                        <a
                        href="\NSF1H"
                        target="_blank"
                            style={{
                                backgroundColor: '#0054A5',
                                boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                color: '#fff',
                                padding: '0.2rem 2.3rem',
                                border: 'none',
                                borderRadius: '0.5rem',
                                fontWeight: '700',
                                fontSize: '1.1rem',
                                margin: '0 auto 1.7rem',
                            }}
                        >
                            Hindi
                        </a> */}
                    </div>
                    <Grid container spacing={3} justifyContent="center">
                        {/* First Row: 4 Items */}
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={styles.itemContainer}>
                                <div style={styles.upperHalf}>
                                    <div style={styles.iconAndTitleContainer}>
                                        {/* <div style={styles.whiteCircle}></div> White circle */}
                                        <div style={{ backgroundColor: '#fff', borderRadius: '50%', padding: 10 }}><img style={styles.icons} src={users} alt="" /></div>

                                        <h3 style={styles.itemTitle}>No of Visitors</h3>
                                    </div>
                                </div>
                                <div style={styles.lowerHalf}>
                                    <h2 style={styles.statValue}>{Object.keys(userStats).length <= 0 ? "Fetching..." : userStats.count}</h2>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={styles.itemContainer}>
                                <div style={styles.upperHalf}>
                                    <div style={styles.iconAndTitleContainer}>
                                        {/* <div style={styles.whiteCircle}></div> White circle */}
                                        <div style={{ backgroundColor: '#fff', borderRadius: '50%', padding: 10 }}><img style={styles.icons} src={artconnect} alt="" /></div>

                                        <h3 style={styles.itemTitle}>No of Art Connects Downloads</h3>
                                    </div>
                                </div>
                                <div style={styles.lowerHalf}>
                                    <h2 style={styles.statValue}>{Object.keys(userStats).length <= 0 ? "Fetching..." : userStats.artConnectDownloadCount + 10144}</h2>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={styles.itemContainer}>
                                <div style={styles.upperHalf}>
                                    <div style={styles.iconAndTitleContainer}>
                                        {/* <div style={styles.whiteCircle}></div> White circle */}
                                        <div style={{ backgroundColor: '#fff', borderRadius: '50%', padding: 10 }}><img style={styles.icons} src={novels} alt="" /></div>

                                        <h3 style={styles.itemTitle}>No of Novels Downloads</h3>
                                    </div>
                                </div>
                                <div style={styles.lowerHalf}>
                                    <h2 style={styles.statValue}>{Object.keys(userStats).length <= 0 ? "Fetching..." : userStats.novellDownloadCount + 10280}</h2>
                                </div>
                            </div>
                        </Grid>
	    {/* <Grid item xs={12} sm={6} md={3}>
                            <div style={styles.itemContainer}>
                                <div style={styles.upperHalf}>
                                  <div style={styles.iconAndTitleContainer}>
                                        {/* <div style={styles.whiteCircle}></div> White circle 
                                        <div style={{ backgroundColor: '#fff', borderRadius: '50%', padding: 10 }}><img style={styles.icons} src={avgscr} alt="" /></div>

                                        <h3 style={styles.itemTitle}>Average Score in Quiz</h3>
                                    </div>
                                </div>
                                <div style={styles.lowerHalf}>
                                    <h2 style={styles.statValue}>{Object.keys(userStats).length <= 0 ? "Fetching..." : 11.38}</h2>
                                </div>
                            </div>
                        </Grid>*/}
                    </Grid>
                    <Grid container justifyContent="center" spacing={3} style={styles.secondRow}>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={styles.itemContainer}>
                                <div style={styles.upperHalf}>
                                    <div style={styles.iconAndTitleContainer}>
                                        {/* <div style={styles.whiteCircle}></div> White circle */}
                                        <div style={{ backgroundColor: '#fff', borderRadius: '50%', padding: 10 }}><img style={styles.icons} src={quiz} alt="" /></div>

                                        <h3 style={styles.itemTitle}>No of Users Played Quiz</h3>
                                    </div>
                                </div>
                                <div style={styles.lowerHalf}>
                                    <h2 style={styles.statValue}>{Object.keys(userStats).length <= 0 ? "Fetching..." : userStats.playedQuiz + 10295}</h2>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={styles.itemContainer}>
                                <div style={styles.upperHalf}>
                                    <div style={styles.iconAndTitleContainer}>
                                        {/* <div style={styles.whiteCircle}></div> White circle */}
                                        <div style={{ backgroundColor: '#fff', borderRadius: '50%', padding: 10 }}><img style={styles.icons} src={modules} alt="" /></div>

                                        <h3 style={styles.itemTitle}>No of Modules Downloads</h3>
                                    </div>
                                </div>
                                <div style={styles.lowerHalf}>
                                    <h2 style={styles.statValue}>{Object.keys(userStats).length <= 0 ? "Fetching..." : userStats.modulesDownloadCount + 16942}</h2>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={styles.itemContainer}>
                                <div style={styles.upperHalf}>
                                    <div style={styles.iconAndTitleContainer}>
                                        <div style={{ backgroundColor: '#fff', borderRadius: '50%', padding: 10 }}><img style={styles.icons} src={certificates} alt="" /></div>

                                        <h3 style={styles.itemTitle}>No of Certificate Downloads</h3>
                                    </div>
                                </div>
                                <div style={styles.lowerHalf}>
                                    <h2 style={styles.statValue}>{Object.keys(userStats).length <= 0 ? "Fetching..." : userStats.certificateDownloadCount}</h2>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    );
}

export default UsersStats;
