import React from 'react';
import './SmallSquare.css';
import {IP} from '../../connection';

function SmallSquare(props) {
    // //console.log((500 / 4) * (props.value % 4),(500 / 4) * (Math.floor(props.value / 4)))
    const tileStyle = {
        width: '100%',
        height: '100%',
        transiion:'2s ease',
        backgroundImage: `url("${IP+"getImage/?imgName=" + props.imgUrl[props.imageIndex]}")`,
        backgroundSize: `${props.widthSize}px`,//x and y are nedded to the background  image size 
        // backgroundSize:'cover',
        backgroundPosition: `${(133 / 4) * (props.value % 4)}% ${(133 / 4) * (Math.floor(props.value / 4))}%`,
        // backgroundPosition: `${(500 / 4) * (props.value % 4)}px ${(500 / 4) * (Math.floor(props.value / 4))}px`,
    
      };
    //   //console.log(tileStyle)
      function drag(ev) {
        // //console.log(ev.target)
        ev.dataTransfer.setData("text", ev.target.id);
    }
    return (
        
        <div onClick={(e)=>props.clickHandler(e)} id={'FillSquare'+props.value} style={{...tileStyle}} draggable="true" onDragStart={(event)=>drag(event)}>
        </div>
    )
}

export default SmallSquare;