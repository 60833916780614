import React, { useState, useEffect } from "react";

import Footer from "../screens/Footer";
import ArtConnectStyles from './ArtConnect.module.css';
import './MultiLingual.css';
import { Grid } from '@mui/material';
import rocket1 from '../resources/assets/Images/artconnecticons/Rocket1.webp';
import rocket2 from '../resources/assets/Images/artconnecticons/Rocket2.webp';
import rocket3 from '../resources/assets/Images/artconnecticons/Rocket3.webp';
import rocket4 from '../resources/assets/Images/artconnecticons/Rocket4.webp';
import rocket5 from '../resources/assets/Images/artconnecticons/Rocket5.webp';
import rocket6 from '../resources/assets/Images/artconnecticons/Rocket6.webp';
import rocket7 from '../resources/assets/Images/artconnecticons/Rocket7.webp';
import rocket8 from '../resources/assets/Images/artconnecticons/Rocket8.webp';

import doodle1 from '../resources/assets/EXPORTS/ART CONNECT/EXPORTS/doodle/4x/Asset 10@4x.webp'
import doodle2 from '../resources/assets/EXPORTS/ART CONNECT/EXPORTS/doodle/4x/Asset 11@4x.webp'
import doodle3 from '../resources/assets/EXPORTS/ART CONNECT/EXPORTS/doodle/4x/Asset 12@4x.webp'
import doodle4 from '../resources/assets/EXPORTS/ART CONNECT/EXPORTS/doodle/4x/Asset 13@4x.webp'
import doodle5 from '../resources/assets/EXPORTS/ART CONNECT/EXPORTS/doodle/4x/Asset 14@4x.webp'
import doodle6 from '../resources/assets/EXPORTS/ART CONNECT/EXPORTS/doodle/4x/Asset 15@4x.webp'
import doodle7 from '../resources/assets/EXPORTS/ART CONNECT/EXPORTS/doodle/4x/Asset 16@4x.webp'
import doodle8 from '../resources/assets/EXPORTS/ART CONNECT/EXPORTS/doodle/4x/Asset 17@4x.webp'
import doodle9 from '../resources/assets/EXPORTS/ART CONNECT/EXPORTS/doodle/4x/Asset 9@4x.webp'


import rocket1PDF from '../resources/assets/pdf/Rocket.pdf';
import rocket2PDF from '../resources/assets/pdf/PSLV.pdf';
import rocket3PDF from '../resources/assets/pdf/APJ ABDUL KALAAM.pdf';
import rocket4PDF from '../resources/assets/pdf/Pragyan Rover.pdf';
import rocket5PDF from '../resources/assets/pdf/Astronout.pdf';
// import rocket6PDF
import rocket6PDF from '../resources/assets/pdf/draw_the_symmetry_com.pdf';

import rocket7PDF from '../resources/assets/pdf/how_many_com.pdf';
import rocket8PDF from '../resources/assets/pdf/spot_the_difference_com.pdf';
import { Container } from "react-bootstrap";
import TopHeader from "./TopHeader";
import userService from '../Services/userService';

function ArtConnect() {
  const handleClick = () => {
    userService.getInstance().postUserStatCount({"counter": "artConnectDownloadCount"}).then((json) => {
      console.log(json)
    });
  }
  return (
    <>
      <TopHeader to="/activities" />

      {
        <Container className="mainDiv" style={{ color: '#000' }}>

          <div style={{ margin: '2% auto 0' }}>
            <button
              style={{
                backgroundColor: '#0054A5',
                boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                color: '#fff',
                padding: '0.5rem 2.3rem',
                border: 'none',
                borderRadius: '0.6rem',
                fontWeight: '700',
                fontSize: '1.3rem',
                margin: '0 auto 1.7rem',
              }}

              disabled
            >
              ART CONNECT
            </button>
          </div>

          <Grid container alignItems="center" style={{ position: 'relative', backgroundColor: 'rgba(40, 83, 118, 0.5)', borderRadius: 50, padding: '20px 40px', width: "100%", margin: "2% auto", justifyContent: 'center' }}>
            <img className={ArtConnectStyles.ArtCntdoodle1} style={{}} src={doodle1} alt="e" />
            <img className={ArtConnectStyles.ArtCntdoodle2} style={{}} src={doodle2} alt="e" />
            <img className={ArtConnectStyles.ArtCntdoodle3} style={{}} src={doodle3} alt="e" />
            <img className={ArtConnectStyles.ArtCntdoodle4} style={{}} src={doodle4} alt="e" />
            <img className={ArtConnectStyles.ArtCntdoodle5} style={{}} src={doodle5} alt="e" />
            <img className={ArtConnectStyles.ArtCntdoodle6} style={{}} src={doodle6} alt="e" />
            <img className={ArtConnectStyles.ArtCntdoodle7} style={{}} src={doodle7} alt="e" />
            <img className={ArtConnectStyles.ArtCntdoodle8} style={{}} src={doodle8} alt="e" />
            <img className={ArtConnectStyles.ArtCntdoodle9} style={{}} src={doodle9} alt="e" />
            <Grid item xs={5} sm={4} md={4} xl={4} lg={4} style={{ margin: '10px auto', }}>
              <a
                href={rocket3PDF}
                onClick={() => handleClick()}
                download="APJ ABDUL KALAAM"
                target="_blank"
                rel="noreferrer"
              >
                <div style={{ width: '80%', margin: '0 auto' }}>
                  <img style={{ width: '100%' }} src={rocket3} alt="e" />

                </div>
              </a>

            </Grid>

            <Grid item xs={4} sm={2} md={2} xl={2} lg={2} style={{ margin: '10px auto', }}>
              <a
                href={rocket6PDF}
                onClick={() => handleClick()}
                download="Astronout"
                target="_blank"
                rel="noreferrer"
              >
                <div style={{ width: '80%', margin: '0 auto' }}>
                  <img style={{ width: '100%' }} src={rocket6} alt="e" />

                </div>
              </a>

            </Grid>

            <Grid item xs={3} sm={1.5} md={1.5} xl={1.5} lg={1.5} style={{ margin: '10px auto', }}>
              <a
                href={rocket7PDF}
                onClick={() => handleClick()}
                download="Astronout"
                target="_blank"
                rel="noreferrer"
              >
                <div style={{ width: '80%', margin: '0 auto' }}>
                  <img style={{ width: '100%' }} src={rocket7} alt="e" />

                </div>
              </a>

            </Grid>
            <Grid item xs={4} sm={3} md={3} xl={3} lg={3} style={{ margin: '10px auto', }}>
              <a
                href={rocket8PDF}
                onClick={() => handleClick()}
                download="Astronout"
                target="_blank"
                rel="noreferrer"
              >
                <div style={{ width: '80%', margin: '0 auto' }}>
                  <img style={{ width: '100%' }} src={rocket8} alt="e" />

                </div>
              </a>


            </Grid>
            <Grid item xs={4} sm={2} md={2} xl={2} lg={2} style={{ margin: '10px auto', }}>
              <a
                href={rocket2PDF}
                onClick={() => handleClick()}
                download="PSLV"
                target="_blank"
                rel="noreferrer"
              >
                <div style={{ width: '80%', margin: '0 auto' }}>
                  <img style={{ width: '100%' }} src={rocket2} alt="e" />

                </div>
              </a>
            </Grid>
            <Grid item xs={3} sm={1.5} md={1.5} xl={1.5} lg={1.5} style={{ margin: '10px auto', }}>
              <a
                href={rocket5PDF}
                onClick={() => handleClick()}
                download="Astronout"
                target="_blank"
                rel="noreferrer"
              >
                <div style={{ width: '80%', margin: '0 auto' }}>
                  <img style={{ width: '100%' }} src={rocket5} alt="e" />

                </div>
              </a>

            </Grid>
            <Grid item xs={6} sm={4.5} md={4.5} xl={4.5} lg={4.5} style={{ margin: '10px auto', }}>
              <a
                href={rocket4PDF}
                onClick={() => handleClick()}
                download="Pragyan Rover"
                target="_blank"
                rel="noreferrer"
              >
                <div style={{ width: '80%', margin: '0 auto' }}>
                  <img style={{ width: '100%' }} src={rocket4} alt="e" />

                </div>
              </a>
            </Grid>
            <Grid item xs={5} sm={4} md={4} xl={4} lg={4} style={{ margin: '10px auto', }}>
              <a
                href={rocket1PDF}
                onClick={() => handleClick()}
                download="Rocket"
                target="_blank"
                rel="noreferrer"
              >
                <div style={{ width: '80%', margin: '0 auto' }}>
                  <img style={{ width: '100%' }} src={rocket1} alt="e" />

                </div>
              </a>

            </Grid>


          </Grid>




        </Container>

      }
      <Footer />
    </>
  )

}
export default ArtConnect;