import React, { useEffect, useState } from "react";
import { Grid, Paper } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Logo1 from '../resources/assets/assets/LOGO CHANDRAYAN-02.png';
import Logo2 from '../resources/assets/assets/header.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import homeIcon from '../resources/assets/Images/icons/home.svg';
import backIcon from '../resources/assets/Images/icons/back.svg';

function TopHeader(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showButtons, setShowButtons] = useState(false); // State to control button visibility
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(props);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {

    const pagesWithButtons = ["/quiz", "/picturePuzzle", "/puzzle", "/novels", "/inspiringstories", "/userstats", "/launchevents", "/artconnect", "/eresources"];

    // Check if the current path is in the list of routes with buttons
    setShowButtons(pagesWithButtons.includes(location.pathname));
  }, [location.pathname]);

  const isMobile = windowWidth <= 600;

  const theme = createTheme();

  const goHome = () => {
    navigate("/");
  };

  const goBack = () => {
    if(props.reload){
      window.location.reload();
    }
    else{
    navigate(props.to);

    }
  };

  const styles = {
    paper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    container: {
      width: '100%',
    },
    imgLogo1: {
      maxWidth: isMobile ? '45%' : '45%',
      height: 'auto',
      cursor: 'pointer'
    },
    imgLogo2: {
      width: isMobile ? '75%' : '80%', // Original size for header.png
      height: 'auto',
    },
    button: {
      width: '100%', // Adjusted size to help with alignment
      height: 'auto',
      backgroundColor: 'rgb(0, 84, 165)',
      border: 'none',
      borderRadius: '50%',
      cursor: 'pointer',
      outline: 'none',
      padding: '5px',
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px', // Adjust spacing between buttons and logo
    },
    Logo1: {
      order: isMobile ? 2 : 1,
      justifyContent: isMobile ? 'center' : 'flex-start',
    },
    Logo2: {
      order: isMobile ? 1 : 2,
      justifyContent: 'center',
    },
    Logo3: {
      order: 3,
      display: isMobile ? 'none' : 'block',
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper style={styles.paper}>
        <Container fluid style={styles.container}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} xl={3.5} container style={styles.Logo1}>
              {/* <a href=""> */}
    
              <img style={styles.imgLogo1} src={Logo1} alt="Logo 1" onClick={()=>navigate("/")}/>
              {/* </a> */}
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5} container justifyContent="center" alignItems="center" style={styles.Logo2}>
              <div style={styles.buttonContainer}>
                {showButtons && (
                  // {(location.pathname=='/activities' || location.pathname=='/') ? (
                  <>
                    <button onClick={goHome} style={styles.button}>
                      <img src={homeIcon} alt="Home" style={{ width: '100%', height: '100%' }} />
                    </button>
                    <img style={styles.imgLogo2} src={Logo2} alt="Logo 2" />
                    <button onClick={goBack} style={styles.button}>
                      <img src={backIcon} alt="Back" style={{ width: '100%', height: '100%' }} />
                    </button>
                  </>
                )
                  // :
                  // <img style={styles.imgLogo2} src={Logo2} alt="Logo 2" />

                }
                {!showButtons && (
                  <img style={styles.imgLogo2} src={Logo2} alt="Logo 2" />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} xl={3.5} container justifyContent="flex-end" style={styles.Logo3}>
              {/* Empty Section for alignment */}
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </ThemeProvider>
  );
}

export default TopHeader;