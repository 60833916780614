import Quiz from "../screens/Quiz";
import Footer from "../screens/Footer";
import React, { useState } from "react";
import EventDataParser from "../Services/EventDataParser";
import QuizModal from "./QuizModal";
import TopHeader from "./TopHeader";
import userService from '../Services/userService'
import { useNavigate } from 'react-router-dom';


function ImplementQuiz() {
  const [name, setName] = useState('');
  const [grade, setGrade] = useState('');
  const [gameData, setGameData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const gradeData = { "pre": 1, "mid": 2, "sec": 3 }
  const [gameAttr, setGameAttr] = useState({});
  const [day, setDay] = useState('');
  const [modalStatus, setModalStatus] = useState(true)
  const navigate = useNavigate();


  React.useEffect(() => {
    if (gameData.length <= 0)
      getTileData();
  }, []);

  


  async function getTileData() {
    let data = await EventDataParser();
    setGameData(data);
  }

  async function modalHandler(name, grade) {
    setModalStatus(false);
    if (name.length <= 0) {
      alert("Enter your name");
      setModalStatus(true);
      return;
    }
    else if (grade.length <= 0) {
      alert("Enter your grade");
      setModalStatus(true);
      return;
    }
    setName(name);
    setGrade(grade);
    setModalStatus(false);

    if (gameData.length > 0) {

      gameData.forEach(element => {
        let newGameData = element[0];
        if (newGameData.tile_grade_id === gradeData[grade]) {
          // if(newGameData.tile_grade_id === gradeData['mid']){
          setEventData(newGameData);
          if (newGameData.event_type_id == 2) {
            let gameData = newGameData.tile_game_info[0].multiGameData
            setGameData(gameData);
            let getDate = newGameData.tile_game_info[0].forDate;
            let day = getDate
            setDay(day);
            //////////////////////////////////////////////////////////////////////////// 
            let game = newGameData.tile_game_info[0].multiGameData[0].game;
            game.forEach(element => {
              if (element.game_type_id === 3) {
                let gameAttr = element;
                // console.log(typeof(gameAttr))
                setGameAttr(element)
              }
            });

          }
        }
      });
    }
    userService.getInstance().postUserStatCount({"counter": "playedQuiz"}).then((json) => {
      console.log(json)
    });
  }

  return (
    <>
      <TopHeader to="/activities" />
      {
        (name.length <= 0 || grade.length <= 0) &&
        <QuizModal onClose={modalHandler} modalStatus={modalStatus} />
      }
      {
        (gameData.length > 0 && Object.keys(eventData).length > 0 && Object.keys(gameAttr).length > 0) &&
        <Quiz
          gameAttr={gameAttr}
          gameData={gameData}
          eventData={eventData}
          day={day}
          name={name}
          grade={grade}
        />
      }

      <Footer />
    </>
  );
}

export default ImplementQuiz;