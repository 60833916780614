import React, { useState, useEffect, useRef } from "react";
import Board from "./Board";
import { updateURLParameter } from "./helpers";
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PuzzleSolution from './puzzleSolution'
import { IP } from '../../connection';
import './picture.css';
import Boy from '../../resources/assets/Girl.png'
import { Grid } from '@mui/material';
import {
  useLocation,
} from "react-router";
import Header from "../../screens/Header";
import Footer from "../../screens/Footer";
import chLogo from '../../resources/assets/chlogo.png';
import jg3 from '../../resources/assets/jigsaw3.png';
import jg4 from '../../resources/assets/jigsaw4.png';
import BackButton from "../backButton";
import "./Carousel.css";
import TopHeader from "../TopHeader";


function PicturePuzzle(props) {
  const location = useLocation();

  const imgindex = useRef(0);
  const [imgUrl, setImgUrl] = useState(IP + "getImage/?imgName=" + props.gameAttr.game_attr[0].options[imgindex.current])
  const [imgDesc, setImgDesc] = useState('');
  const [userData, setUserData] = useState([]);
  useEffect(async () => {

    if (imgDesc.length === 0) { await setDesc() };

  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has("img")) {
      setImgUrl(urlParams.get("img"))

    }
  }, [])

  const setDesc = async () => {
    var data = props.gameAttr.game_attr[0].feedback[imgindex.current];
    data = data.split('/n');
    //console.log(data);
    if (data.length > 0) {
      if (userData.lang_id === 2) {
        setImgDesc(data[1])
      }
      else {
        setImgDesc(data[0])
      }
    }
    else {
      setImgDesc(props.gameAttr.game_attr[0].feedback[imgindex.current]);
    }
  }
  const handleImageChange = () => {
    // alert("hello:"+props.gameAttr.game_attr[0].options);
    console.log("hello:" + props.gameAttr.game_attr[0].options);

    if (imgindex.current < props.gameAttr.game_attr[0].options.length - 1) {
      imgindex.current = imgindex.current + 1;
    }

    setImgUrl(IP + "getImage/?imgName=" + props.gameAttr.game_attr[0].options[imgindex.current]);
    setDesc();
  }

  const [active, setActive] = useState(false);
  function solutionHandler(data) {
    setActive(true)
    if (data === false) {
      setActive(false)
    }
  }
  const [levelClick, setlevelClick] = useState(0);

  const handleButtonClick = (e) => {
    setlevelClick(e);
  }



  const slides = props.gameAttr.game_attr[0].options;
  // const [slide, setSlide] = useState(0);
  const setSlide = (idx) => {
    imgindex.current = idx;

    setImgUrl(IP + "getImage/?imgName=" + props.gameAttr.game_attr[0].options[imgindex.current]);
    setDesc();
  }

  const nextSlide = () => {
    imgindex.current = imgindex.current === props.gameAttr.game_attr[0].options.length - 1 ? 0 : imgindex.current + 1;

    setImgUrl(IP + "getImage/?imgName=" + props.gameAttr.game_attr[0].options[imgindex.current]);
    setDesc();
  };

  const prevSlide = () => {
    imgindex.current = imgindex.current === 0 ? props.gameAttr.game_attr[0].options.length - 1 : imgindex.current - 1;

    setImgUrl(IP + "getImage/?imgName=" + props.gameAttr.game_attr[0].options[imgindex.current]);
    setDesc();
  };


  return (
    <>
    {
      levelClick ===0 ?
      <TopHeader to="/activities" />
      :
      <TopHeader to="/picturePuzzle" reload={true} />

    }
      {/* <TopHeader to="/activities" /> */}
      {/* <div class="chLogoTopDiv">
        <div className="chLogoTopHeader" >
           <img className='chimgtopHeader' src={chLogo} alt="Logo 2" />
           {    
                    userData.lang_id===2 ?
       <h2>चित्रखंड पहेली</h2>
       :
       <h2>Puzzle</h2>
           }
        </div>
        </div>
    <BackButton/> */}
      <div style={{ margin: '2% auto 0', zIndex: '1' }}>
        <button
          style={{
            backgroundColor: '#0054A5',
            boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
            color: '#fff',
            padding: '0.5rem 2.3rem',
            border: 'none',
            borderRadius: '0.6rem',
            fontWeight: '700',
            fontSize: '1.3rem',
            margin: '0 auto 1.7rem',
          }}
          disabled
        >
          {userData.lang_id === 2 ? 'चित्रखंड पहेली' : 'PICTURE PUZZLE'}
        </button>
      </div>
      <div>
        {levelClick === 0 ?
          <>

            <div className="game" style={{}}>
              <Grid container alignItems="center" style={{ width: "60%", margin: "4% auto 1%", justifyContent: 'center', backgroundColor: 'rgba(40, 83, 118, 0.5)', color: '#000', borderRadius: '0.625rem', border: '1px solid rgba(0,0,0,0.1)' }}>
                
                <Grid item xs={12} sm={12} md={12} xl={6} lg={6} style={{}}>
                  <div onClick={() => handleButtonClick(3)} style={{ cursor: 'pointer', color: 'white', textAlign: 'center', margin: '20px' }}>
                    <img className='imgBox' src={jg3} alt="Logo 2" style={{ width: '70%' }} />
                    {
                      userData.lang_id === 2 ?
                        <h4 style={{ color: 'white' }}>स्तर 1</h4>
                        :
                        <h4 style={{ color: 'white' }}>Level 1</h4>
                    }
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={6} lg={6} style={{}}>
                  <div onClick={() => handleButtonClick(4)} style={{ cursor: 'pointer', color: 'white', textAlign: 'center', margin: '20px' }}>
                    <img className='imgBox' src={jg4} alt="Logo 2" style={{ width: '70%' }} />
                    {
                      userData.lang_id === 2 ?
                        <h4 style={{ color: 'white' }}>स्तर 2</h4>
                        :
                        <h4 style={{ color: 'white' }}>Level 2</h4>
                    }
                  </div>
                </Grid>
               
              </Grid>
            </div>
          </>
          :

          <>

            <div className="game" style={{}}>

              {!active &&
                <>
                  <Grid container alignItems="center" style={{ width: "90%", margin: "2% auto", justifyContent: 'center' }}>
                    <Grid item xs={0} sm={0} md={0} xl={4} lg={0} style={{ margin: '10px auto', position: 'relative' }}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} xl={4} lg={5.5} style={{ margin: '10px auto', }}>


                      <span className="indicators">
                        {/* <button onClick={prevSlide} className="arrow arrow-left">prev</button> */}
                        {slides.map((item, idx) => {
                          return (
                            <>
                              <div className="slidePaginationsDiv">
                                <img
                                  src={IP + "getImage/?imgName=" + item}
                                  alt="images"
                                  key={idx}
                                  className={imgindex.current === idx ? "slidePaginations" : "slidePaginations slidePaginations-hidden"}
                                  onClick={() => setSlide(idx)}
                                />

                              </div>
                            </>
                          );
                        })}
                        {/* <button onClick={nextSlide} className="arrow arrow-right">next</button> */}

                      </span>

                      <div className="boardDiv">

                        <Board imgUrl={imgUrl} levelClick={levelClick} langID={userData.lang_id} handleImageChange={handleImageChange} />
                        {
                          props.eventType == 1 &&
                          <button onClick={solutionHandler} style={{ borderRadius: "10px", padding: "1% 2%", border: "none", backgroundColor: "rgba(14, 136, 211, 1)", color: "white" }}>SUBMIT</button>
                        }
                        {
                          props.eventType == 2 &&
                          <div style={{ width: '20%' }}>
                            <NavLink
                              // ImplementCardBoard
                              to="/activityBoard" state={{ eventIndex: props.eventID, id: props.id }}>
                              {
                                userData.lang_id === 2 ?
                                  <button style={{
                                    width: '100%', backgroundColor: '#0054A5',
                                    boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                    color: '#fff',
                                    padding: '0rem 1.3rem',
                                    border: 'none',
                                    borderRadius: '0.5rem',
                                    fontWeight: '700',
                                    fontSize: '1.1rem', margin: '0 auto 1.7rem',
                                  }}>सेव करें</button>
                                  :
                                  <button style={{
                                    width: '100%', backgroundColor: '#0054A5',
                                    boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                    color: '#fff',
                                    padding: '0rem 1.3rem',
                                    border: 'none',
                                    borderRadius: '0.5rem',
                                    fontWeight: '700',
                                    fontSize: '1.1rem', margin: '0 auto 1.7rem',
                                  }}>Finish</button>
                              }
                            </NavLink>
                          </div>
                        }
                      </div>

                    </Grid>
                    {
                      userData.lang_id === 2 ?

                        <Grid item xs={12} sm={12} md={5} xl={4} lg={5} style={{ margin: '10px auto', textAlign: 'left' }} className="jgliDiv" >

                          <div style={{ backgroundColor: 'rgba(255,255,255,0.7)', borderRadius: '15px', padding: '20px', boxShadow: '5px 5px 10px rgba(0,0,0,0.2)', margin: '5% auto', width: '80%' }}>
                            <h4>पहेली कैसे सुलझाएँ?</h4>
                            <ul>

                              <li>चित्र को पुनः बनाने के लिए अव्यवस्थित चित्र खण्डों को पुनर्व्यवस्थित करें।</li>
                              <li>याद रखें, आप एक समय में केवल एक ही खंड का स्थान बदल सकते हैं।</li>
                              <li>यदि आप सहायता चाहते हैं, तो आप पहेली के नीचे दिए गए ‘वास्तविक प्रतिबिम्ब’ बटन पर क्लिक कर सकते हैं।</li>
                            </ul>
                          </div>
                          <div className="" style={{ backgroundColor: 'rgba(255,255,255,0.7)', borderRadius: '15px', padding: '20px', boxShadow: '5px 5px 10px rgba(0,0,0,0.2)', margin: '5% auto', width: '80%' }}>
                            <h4>चित्र के बारे में</h4>
                            <ul>
                              <li>{imgDesc}</li>

                            </ul>
                          </div>
                        </Grid>
                        :
                        <Grid item xs={12} sm={12} md={5} xl={4} lg={5} style={{ margin: '10px auto', textAlign: 'left' }} className="jgliDiv" >

                          <div style={{ backgroundColor: 'rgba(255,255,255,0.7)', borderRadius: '15px', padding: '20px', boxShadow: '5px 5px 10px rgba(0,0,0,0.2)', margin: '5% auto', width: '80%' }}>
                            <h4>How to solve the puzzle?</h4>
                            <ul>

                              <li>To complete this puzzle rearrange the jumbled picture tiles to recreate the picture.</li>
                              <li>If needed you can use the clue about the picture to solve the puzzle.</li>
                              <li>Remember, you can move only one tile at a time.</li>
                            </ul>
                          </div>
                          <div className="" style={{ backgroundColor: 'rgba(255,255,255,0.7)', borderRadius: '15px', padding: '20px', boxShadow: '5px 5px 10px rgba(0,0,0,0.2)', margin: '5% auto', width: '80%' }}>
                            <h4>About the Picture</h4>
                            <ul>
                              <li>{imgDesc}</li>

                            </ul>
                          </div>
                        </Grid>
                    }
                  </Grid>
                </>
              }
            </div>

            {
              active &&
              <PuzzleSolution data={solutionHandler} value={props} />
            }
          </>

        }

        {/* paste puzzle code heres*/}
      </div>
      <Footer />
    </>
  );
}

export default PicturePuzzle;