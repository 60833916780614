import React from "react";
import { IP } from '../connection'
import axios from "axios";


export default function saveUserDataToDB() {
    // console.log("saveUserDataToDB");
let userData=localStorage.getItem("userStats");

if(userData===null)
{
    return true;
}
else{
    // console.log(userData);
    return new Promise((resolve, reject) => {
        axios.post(IP+'userMetrics',JSON.parse(userData))
          .then(function (response) {
            if(response.data.code=== 200)
            {
                localStorage.removeItem('userStats')
            }
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
  
      })
}
}