import React, { useState } from 'react';
import './QuizModal.css';
import { useNavigate } from 'react-router-dom';


export default function QuizModal({ onClose, modalStatus }) {
  const [isModalOpen, setIsModalOpen] = React.useState(modalStatus);
  const [name, setName] = useState('');
  const [grade, setGrade] = useState('');
  const navigate = useNavigate();

  

  const closeModal = () => {
    // onClose(name, grade);
    navigate("/activities");

  };

  const handleStageClick = (stage) => {
    setGrade(stage);
  };

  const handleSubmit = () => {
    // closeModal();
    onClose(name, grade);

  };

  return (
    <div className="App">
      {isModalOpen && (
        <div className="contentOverlayBox" onClick={closeModal}>
          <div className="content-box" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>×</button>
            <div className="text-div">
              <label htmlFor="name" className="content-label">Name</label>
              <input
                id="name"
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="button-group">
              <div className="text-div">
                <label htmlFor="stage" className="content-label">Stage</label>
              </div>
              <div className="button-row">
                <button
                  onClick={() => handleStageClick('pre')}
                  className={grade === 'pre' ? 'active' : ''}
                >
                  Preparatory
                </button>
                <button
                  onClick={() => handleStageClick('mid')}
                  className={grade === 'mid' ? 'active' : ''}
                >
                  Middle
                </button>
                <button
                  onClick={() => handleStageClick('sec')}
                  className={grade === 'sec' ? 'active' : ''}
                >
                  Secondary
                </button>
              </div>
            </div>

            <button onClick={handleSubmit} className="submit-button">Submit</button>
          </div>
        </div>
      )}
    </div>
  );
}
