import React from "react";
import Footer from "../screens/Footer";
import './MultiLingual.css';
import Container from 'react-bootstrap/Container';
import { Grid } from '@mui/material';
import rackImage from '../resources/assets/EXPORTS/NOVEL/PNG/Asset 13@4x.png';
import TopHeader from "./TopHeader";
import userService from '../Services/userService';



const Novels = () => {
  const [flipData, setFlipData] = React.useState("")

  const moduleClick = () => {
    userService.getInstance().postUserStatCount({"counter": "novellDownloadCount"}).then((json) => {
      console.log(json)
    });
  }

  return (
    <>
      <TopHeader to="/activities" />
      <div style={{ margin: '2% auto 0' }}>
        <button
          style={{
            backgroundColor: '#0054A5',
            boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
            color: '#fff',
            padding: '0.5rem 2.3rem',
            border: 'none',
            borderRadius: '0.6rem',
            fontWeight: '700',
            fontSize: '1.3rem',
            margin: '0 auto 1.7rem',
          }}
          disabled
        >
      GRAPHIC NOVELS
        </button>
      </div>


      <div className="mainDiv" style={{ color: '#000', height: 'auto', zIndex: '1' }}>
        {
          flipData != "" ?

            // <Flipbooks flipBookUrl={flipData} />
            <h1>Flipbook</h1>

            :
            <>
              <Container style={{ backgroundColor: 'rgba(40, 83, 118, 0.5)', color: '#000', borderRadius: '0.625rem', border: '1px solid rgba(0,0,0,0.1)', padding: 20 }}>
                <>
                  <Grid container alignItems="center" xs={12} sm={12} md={10} xl={10} lg={10} style={{ width: "100%", margin: "0% auto", justifyContent: 'center' }}>

                    <div style={{ width: '100%', margin: '0px auto', padding: '0%', alignItems: 'end', display: 'flex', justifyContent: 'center' }}>

                      <Grid item xs={2.5} sm={2.5} md={2.5} xl={2.5} lg={2.5} style={{ margin: '0px auto' }}>

                        <a target='blank' style={{ margin: '0px auto ', display: 'block', cursor: 'pointer' }} onClick={() => moduleClick()} href={'/novel/novel1'}>
                          <img style={{ width: '100%' }} src={'/novel/novel1/docs/novel1.pdf_1_thumb.jpg'} alt="e" />
                        </a>
                      </Grid>

                      <Grid item xs={2.5} sm={2.5} md={2.5} xl={2.5} lg={2.5} style={{ margin: '0px auto' }}>

                        <a target='blank' style={{ margin: '0px auto ', display: 'block', cursor: 'pointer' }} onClick={() => moduleClick()} href={'/novel/novel2'}>
                          <img style={{ width: '100%' }} src={'/novel/novel2/docs/novel2.pdf_1_thumb.jpg'} alt="e" />
                        </a>
                      </Grid>

                      <Grid item xs={2.5} sm={2.5} md={2.5} xl={2.5} lg={2.5} style={{ margin: '0px auto' }}>

                        <a target='blank' style={{ margin: '0px auto ', display: 'block', cursor: 'pointer' }} onClick={() => moduleClick()} href={'/novel/novel3'}>
                          <img style={{ width: '100%' }} src={'/novel/novel3/docs/novel3.pdf_1_thumb.jpg'} alt="e" />
                        </a>
                      </Grid>

                      <Grid item xs={2.5} sm={2.5} md={2.5} xl={2.5} lg={2.5} style={{ margin: '0px auto' }}>

                        <a target='blank' style={{ margin: '0px auto ', display: 'block', cursor: 'pointer' }} onClick={() => moduleClick()} href={'/novel/novel4'}>
                          <img style={{ width: '100%' }} src={'/novel/novel4/docs/novel4.pdf_1_thumb.jpg'} alt="e" />
                        </a>
                      </Grid>


                    </div>


                  </Grid>

                  <img
                    src={rackImage}
                    alt="Rack"
                    style={{
                      width: '100%',
                      height: 'auto',
                      marginTop: '-10px',
                    }}
                  />
                </>

              </Container>
            </>
        }
      </div>
      <Footer />
    </>
  )

}
export default Novels;

