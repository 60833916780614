import React, { useEffect, useState } from 'react';
import { Grid, Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Footer from '../screens/Footer';
// import themeData[0].themeImage from '../resources/assets/EXPORTS/MODULES/4x/Asset 1@4x.webp';
// import modG20Logo from '../resources/assets/EXPORTS/MODULES/4x/G20@2x.webp';
// import modNSLogo from '../resources/assets/EXPORTS/MODULES/4x/NAARI SGAKTI@2x.webp';
// import modVBLogo from '../resources/assets/EXPORTS/MODULES/4x/VIKSIT BHARAT@2x.webp';


import rackImage from '../resources/assets/EXPORTS/NOVEL/PNG/Asset 13@4x.png';
import { contentArray } from './SpecialModulesData';
import Flipbooks from "./Flipbook/Flipbooks";

import stylesStories from "./InspiringStories.module.css";
import TopHeader from './TopHeader';
import userService from '../Services/userService';


const InspiringStories = () => {
  const [userData, setUserData] = useState([]);
  const [flipData, setFlipData] = useState("");
  const [langIndex, setLangIndex] = useState(0);
  const [themeData, setThemeData] = useState(contentArray["Chandrayan Utsav"]);
  const [themeKey, setThemeKey] = useState("Chandrayan Utsav");

  const handleChangeTheme = (event) => {
    setThemeData(contentArray[event.target.value]);
    console.log(contentArray[event.target.value]);
    setThemeKey(event.target.value);
    setLangIndex(0);
  };

  const handleChangeLanguages = (event) => {
    setLangIndex(event.target.value);
  };

  const moduleClick = () => {
    // alert("increment")
    userService.getInstance().postUserStatCount({"counter": "modulesDownloadCount"}).then((json) => {
      console.log(json)
    });
  };

  return (
    <>
      <TopHeader to="/activities" />

      <div style={{ margin: '2% auto 0' }}>
        <button
          style={{
            backgroundColor: '#0054A5',
            boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
            color: '#fff',
            padding: '0.5rem 2.3rem',
            border: 'none',
            borderRadius: '0.6rem',
            fontWeight: '700',
            fontSize: '1.3rem',
            margin: '0 auto 1.7rem',
          }}
          disabled
        >
          SPECIAL MODULES
        </button>
      </div>

      {
        flipData != "" ?

          <Flipbooks flipBookUrl={flipData} />

          :
          <>
            <Container style={{ margin: '1.1rem auto 1.7rem', zIndex: 1 }}>
              <Grid container justifyContent="space-between" style={{ backgroundColor: 'rgba(40, 83, 118, 0.5)', color: '#000', borderRadius: '0.625rem', border: '1px solid rgba(0,0,0,0.1)' }}>
                <Grid item xs={12} md={5} style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', borderRadius: '1.25rem', padding: '1.25rem' }}>
                  <Box sx={{ textAlign: 'center' }}>
                    <img
                      src={themeData[0].themeImage}
                      alt="Module Logo"
                      style={{ maxWidth: '90%', height: 'auto', marginBottom: '1.25rem' }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '1rem',
                        marginBottom: '1.25rem',
                        flexDirection: 'row',
                        alignItems: 'center', // Center align items vertically
                        justifyContent: 'center' // Center align items horizontally
                      }}
                    >
                      <FormControl style={{ minWidth: '150px', backgroundColor: '#0054A5', borderRadius: '0.5rem', boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 8px' }}>
                        <InputLabel id="theme-select-label" style={{
                          color: 'rgb(0, 0, 0)',
                          backgroundColor: '#fff', border: 'none', borderRadius: '15px', padding: '5px 10px',
                        }}>Theme</InputLabel>
                        <Select
                          labelId="theme-select-label"
                          value={themeKey}
                          onChange={handleChangeTheme}
                          label="Theme"
                          style={{ color: 'rgb(255, 255, 255)' }}
                          MenuProps={{ PaperProps: { style: { backgroundColor: '#0054A5', color: '#fff' } } }}
                        >
                          {Object.keys(contentArray).map((data, index) => (
                            <MenuItem key={index} value={data} style={{ color: '#fff' }}>
                              {data}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl style={{ minWidth: '150px', backgroundColor: '#0054A5', borderRadius: '0.5rem', boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 8px' }}>
                        <InputLabel id="language-select-label" style={{
                          color: 'rgb(0, 0, 0)',
                          backgroundColor: '#fff', border: 'none', borderRadius: '15px', padding: '5px 10px',
                        }}>Language</InputLabel>
                        <Select
                          labelId="language-select-label"
                          value={langIndex}
                          onChange={handleChangeLanguages}
                          label="Language"
                          style={{ color: 'rgb(255, 255, 255)' }}
                          MenuProps={{ PaperProps: { style: { backgroundColor: '#0054A5', color: '#fff' } } }}
                        >
                          {themeData.map((data, index) => (
                            <MenuItem key={index} value={index} style={{ color: '#fff' }}>
                              {data.Language}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>

                </Grid>
                <Grid item xs={12} md={7} style={{ borderRadius: '1.25rem', padding: '0.625rem' }}>
                  {Array.from({ length: Math.ceil(themeData[langIndex]?.ImageUrl.length / 4) }).map((_, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      <Grid container spacing={1} alignItems="flex-end" className={stylesStories.rackWiseModule}>
                        {themeData[langIndex]?.HyperLink.slice(rowIndex * 4, (rowIndex + 1) * 4).map((data, index) => (
                          <Grid item xs={6} sm={4} md={3} key={index} style={{ textAlign: 'center' }}>
                            <Box
                              sx={{
                                display: 'inline-block',
                                cursor: 'pointer',
                                margin: '0 auto',
                                width: '100%'
                              }}
                              onClick={() => {
                                moduleClick();
                                // console.log("data" + data);
                                // setFlipData(data);
                              }}
                            >
                              <a href={data} target='_blank'>
                              <img
                                src={themeData[langIndex]?.ImageUrl[rowIndex * 4 + index]}
                                alt={`Module ${rowIndex * 4 + index + 1}`}
                                style={{
                                  width: '80%',
                                  // maxWidth: '80px',
                                  marginBottom: '10px',
                                }}
                              /></a>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={12} key={`rack-${rowIndex}`}>
                        <img
                          src={rackImage}
                          alt="Rack"
                          style={{
                            width: '100%',
                            height: 'auto',
                            marginTop: '-25px', // Adjust the margin to make images touch the rack
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </Container>
          </>
      }
      <Footer />
    </>
  );
};

export default InspiringStories;