
import * as React from "react";
import YouTubeVideos from './YoutubeVideos';
import './ButtonWithIcon.css';

function Dashboard() {

  return (
    <>

      <div className="DashboardDiv" >
        <div>
          <div >
          </div>
          <div style={{ margin: '2% auto 0', textAlign:'center' }}>
            <button
              style={{
                backgroundColor: '#0054A5',
                boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                color: '#fff',
                padding: '0.5rem 2.3rem',
                border: 'none',
                borderRadius: '0.5rem',
                fontWeight: '700',
                fontSize: '1.3rem',
                margin: '0 auto 1.7rem',
              }}
              disabled
            >
              LAUNCH EVENTS
            </button>
          </div>
        </div>
        <YouTubeVideos />

      </div>
    </>
  );
}

export default Dashboard;




