import axios from "axios";
import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { IP } from '../connection'
class GetEventRecords extends Component {
  static myInstance = null;

  static getInstance() {
    return new GetEventRecords();
  }
  getRecords(data) {
    //console.log(data);
    return new Promise((resolve, reject) => {
      axios.post(IP+'game/getCurrentTiles',data)
        .then(function (response) {
          // console.log(response.data.data)
          resolve(response.data.data[0]);
        })
        .catch(function (error) {
          reject(error);
          //console.log(error);
        });

    })
  }
}
export default GetEventRecords