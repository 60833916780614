import React, { Component, useState, useRef } from "react";
import './Quiz.css';
import QuizSolution from '../components/QuizSolution';
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IP } from '../connection';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Grid, Paper } from '@mui/material';
import nextI from '../resources/assets/assets/i5.png';
import correct from '../resources/assets/correct.png';
import incorrect from '../resources/assets/incorrects.png';
import Certificate from '../resources/assets/Images/CERTIFICATE.webp';
import ConfettiFunc from "../components/ConfettiFunc";
import userService from '../Services/userService';

class Quiz extends Component {
    constructor(props) {
        super(props);
        this.myDivRef = React.createRef();


        this.QuizData = props.gameAttr.game_attr;

        for (let i = 0; i < this.QuizData.length; i++) {
            this.QuizData[i].selectedAnswer = "";
            this.QuizData[i].score = 0;
            this.QuizData[i].attempt = 0;
        }



    }
    state = {
        langData: [
            { lang_color: "rgba(232, 61, 30, 0.8)", lang_desc: "English", lang_id: 1, lang_name: "en", lang_status: 0, selected: false, __v: 0, _id: "62cc0b87cee79a4163fffff3" },
            { lang_color: "rgba(232, 61, 30, 0.8)", lang_desc: "Hindi", lang_id: 2, lang_name: "hi", lang_status: 0, selected: false, __v: 0, _id: "62cc17a6cee79a4163fffff7" },
            { lang_color: "rgba(232, 61, 30, 0.8)", lang_desc: "Punjabi", lang_id: 10, lang_name: "sa", lang_status: 0, selected: false, __v: 0, _id: "62cc17a6cee79a4163fffff7" },
            { lang_color: "rgba(232, 61, 30, 0.8)", lang_desc: "Marathi", lang_id: 14, lang_name: "sa", lang_status: 0, selected: false, __v: 0, _id: "62cc17a6cee79a4163fffff7" },
            { lang_color: "rgba(232, 61, 30, 0.8)", lang_desc: "Gujarati", lang_id: 16, lang_name: "sa", lang_status: 0, selected: false, __v: 0, _id: "62cc17a6cee79a4163fffff7" },
            { lang_color: "rgba(232, 61, 30, 0.8)", lang_desc: "Bengali", lang_id: 15, lang_name: "sa", lang_status: 0, selected: false, __v: 0, _id: "62cc17a6cee79a4163fffff7" },
            { lang_color: "rgba(232, 61, 30, 0.8)", lang_desc: "Telugu", lang_id: 17, lang_name: "sa", lang_status: 0, selected: false, __v: 0, _id: "62cc17a6cee79a4163fffff7" },
            { lang_color: "rgba(232, 61, 30, 0.8)", lang_desc: "Tamil", lang_id: 22, lang_name: "sa", lang_status: 0, selected: false, __v: 0, _id: "62cc17a6cee79a4163fffff7" },
            { lang_color: "rgba(232, 61, 30, 0.8)", lang_desc: "Kannada", lang_id: 20, lang_name: "sa", lang_status: 0, selected: false, __v: 0, _id: "62cc17a6cee79a4163fffff7" },
            { lang_color: "rgba(232, 61, 30, 0.8)", lang_desc: "Odia", lang_id: 18, lang_name: "or", lang_status: 0, selected: false, __v: 0, _id: "62cc17a6cee79a4163fffff7" },


        ],
        selLang: '',
        comingSoonStatus: false,
        userAnswer: "",
        currentIndex: 0,
        options: [],
        quizEnd: false,
        certificateDate: this.convert(Date.now()),
        score: 0,
        scoretogcertificates: 4,
        nextdisabled: true,
        prevdisabled: false,
        prevAnswer: null,
        flip: false,
        active: false,
        status: false,
        artifactStatus: false,
        artifact: "",
        feedbackStatus: false,
        feedbackUrl: "",
        attempt: 0,
        totalQuestions: 0,
        correct: 0,
        greenLight: false,
        redLight: false,
        optionDisabled: false,
        secondsRemaining: 500,
        // certificateImgURL: 'https://ik.imagekit.io/singh/BuddhiyogaMobileApplication/chandrayaanEnglish_xlY5jRwn-.jpg?updatedAt=1696920199709',
        certificateImgURL: Certificate,
        text: this.props.name,
        showModal: false,
        userData: [],
        startDate: Date.now(),
        monthArray: [],
        selMonth: "",
        monthSelectedStatus: false,
        otherLangData: [],
        timeStatus: true,
        // grade: (this.props.grade === "pre") ? "Primary Grade" : "Secondary Grade"
        grade: (this.props.grade === "pre") ? "Preparatory Stage" : (this.props.grade === "mid") ? "Medium Stage" : "Secondary Stage"

    }

    getMonthName = (data) => {
        let splitData = data.split('/');
        let month = splitData[0];
        let year = splitData[1];
        // console.log(splitData)
        let returnData = {};
        if (year === '2023') {
            switch (month) {
                case '0': { returnData = { name: "Jan " + year, value: data }; break; }
                case '1': { returnData = { name: "Feb " + year, value: data }; break; }
                case '2': { returnData = { name: "Mar " + year, value: data }; break; }
                case '3': { returnData = { name: "Apr " + year, value: data }; break; }
                case '4': { returnData = { name: "May " + year, value: data }; break; }
                case '5': { returnData = { name: "June " + year, value: data }; break; }
                case '6': { returnData = { name: "July " + year, value: data }; break; }
                case '7': { returnData = { name: "Aug " + year, value: data }; break; }
                case '8': { returnData = { name: "Sept " + year, value: data }; break; }
                case '9': { returnData = { name: "Oct " + year, value: data }; break; }
                case '10': { returnData = { name: "Nov " + year, value: data }; break; }
                case '11': { returnData = { name: "Dec " + year, value: data }; break; }
                default: break;
            }
        }
        else if (year === '2024') {
            switch (month) {
                case '0': { returnData = { name: "Jan " + year, value: data }; break; }
                case '1': { returnData = { name: "Feb " + year, value: data }; break; }
                case '2': { returnData = { name: "Mar " + year, value: data }; break; }
                case '3': { returnData = { name: "Apr " + year, value: data }; break; }
                case '4': { returnData = { name: "May " + year, value: data }; break; }
                case '5': { returnData = { name: "June " + year, value: data }; break; }
                 case '6': {returnData= {name:"July "+year, value:data}; break;}
                 case '7': {returnData= {name:"Aug "+year, value:data}; break;}
                // case '8': {returnData= {name:"Sept "+year, value:data}; break;}
                // case '9': if(year!='2024'){returnData= {name:"Oct "+year, value:data}; break;}
                // case '10': if(year!='2024'){returnData= {name:"Nov "+year, value:data}; break;}
                // case '11': if(year!='2024'){returnData= {name:"Dec "+year, value:data}; break;}
                default: break;
            }
        }
        // console.log(returnData);
        return returnData;
    }

    convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    //Component that holds the current quiz
    loadQuiz = () => {
        // console.log("day" + this.props.day);
        let monthData = this.props.eventData.tile_game_info;
        let arr = [];
        monthData.map((data, i) => {
            let returnArray = this.getMonthName(data.forDate);
            if (Object.keys(returnArray).length > 0)
                arr.push(returnArray)
        })
        this.setState(() => {
            return {
                otherLangData: this.props.gameData,
                selMonth: this.props.day,
                userData: this.props.name,
                monthArray: arr,
                selLang: 1,
                text: this.props.name,
                question: this.QuizData[this.state.currentIndex].questions,
                totalQuestions: this.QuizData.length,
                options: this.shuffle(this.QuizData[this.state.currentIndex].options),
                // feedback: this.QuizData[this.state.currentIndex].feedback,
                answer: this.QuizData[this.state.currentIndex].answers,
                value: this.QuizData[this.state.currentIndex].value,
                otherAttr: this.QuizData[this.state.currentIndex].otherAttr,
                // certificateImgURL: 1 === 1 ? 'https://ik.imagekit.io/singh/BuddhiyogaMobileApplication/chandrayaanEnglish_xlY5jRwn-.jpg?updatedAt=1696920199709' : 'https://ik.imagekit.io/singh/BuddhiyogaMobileApplication/chandrayaanHindi_doGH2ayyk.jpg?updatedAt=1696920198967',

            }
        }
        )
    }
    shuffle = (array) => {
        let currentIndex = array.length, randomIndex;
        let feedback = this.QuizData[this.state.currentIndex].feedback
        while (currentIndex > 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            [feedback[currentIndex], feedback[randomIndex]] = [
                feedback[randomIndex], feedback[currentIndex]];

        }
        this.setState({
            feedback: feedback
        })
        return array;
    }

    nextQuestionHanlder = () => {
        if (this.state.attempt != (this.state.currentIndex + 1)) {
            alert("Please select a option for the question.")
        }
        else
            if (this.state.currentIndex === this.QuizData.length - 1) {
                this.finishHandler();
            }
            else {
                this.setState({
                    currentIndex: this.state.currentIndex + 1,
                    userAnswer: null,
                    flip: true,
                    greenLight: false,
                    redLight: false,
                    // optionDisabled:false,
                    showModal: false
                })
            }
    }

    prevQuestionHandler = () => {
        // const {answer, score ,flip} =this.state
        this.setState({
            currentIndex: this.state.currentIndex - 1,
            flip: !this.state.flip
        })


    }

    componentDidMount() {
        // alert('asdas')
        this.loadQuiz();
        this.timerComponent();
    }
    timerComponent = () => {
        this.interval = setInterval(() => {
            if (this.state.secondsRemaining === 0 && this.state.timeStatus === true) {
                this.setState({
                    quizEnd: true,
                    certificateDate: this.convert(Date.now())
                });
                // alert("end")
            }
            this.setState({
                secondsRemaining: this.state.secondsRemaining - 1
            });
            // this.props
        }, 1000);
    }

    //Check the answer
    checkAnswer = (Answer, i) => {
        this.QuizData[this.state.currentIndex].selectedAnswer = Answer;
        const current_score = 0;
        this.setState({
            nextdisabled: false
        })

        if (this.QuizData[this.state.currentIndex].attempt === 0) {
            this.setState({
                attempt: this.state.attempt + 1,
                // optionDisabled:true
            })
            this.QuizData[this.state.currentIndex].attempt = 1;
        }

        if (this.QuizData[this.state.currentIndex].score === 0) {
            if (this.QuizData[this.state.currentIndex].selectedAnswer === this.state.answer[0]) {
                this.setState({
                    score: this.state.score + 1,
                    greenLight: true
                })
                this.QuizData[this.state.currentIndex].score = 1;
            }
            else {
                this.setState({
                    redLight: true
                })
            }
        }
        this.setState({ showModal: true });
        if (this.QuizData[this.state.currentIndex].feedback.length == 4) {

            if (this.QuizData[this.state.currentIndex].feedback[i].length > 0) {
                this.setState({
                    feedbackStatus: true,
                    feedbackUrl: this.QuizData[this.state.currentIndex].feedback[i],
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentIndex } = this.state;
        if (this.state.currentIndex !== prevState.currentIndex) {
            this.setState(() => {
                return {
                    question: this.QuizData[currentIndex].questions,
                    options: this.shuffle(this.QuizData[currentIndex].options),
                    // feedback: this.QuizData[currentIndex].feedback,
                    answer: this.QuizData[currentIndex].answers,
                    value: this.QuizData[currentIndex].value,
                    otherAttr: this.QuizData[currentIndex].otherAttr
                }
            });
        }
    }

    finishHandler = () => {
        // alert("FINISH GAME");
        // var answerStatus = JSON.parse(localStorage.getItem('events'));
        if (this.state.attempt != (this.state.currentIndex + 1) && this.state.score < this.state.scoretogcertificates) {
            alert("Please select a option for the question")
        }
        else {
            // console.log("QUIZ END TRUE asdasdadsasadsa")

            if (this.state.currentIndex <= this.QuizData.length - 1) {
                // console.log("QUIZ END TRUE")
                userService.getInstance().postUserStatCount({"counter": "certificateDownloadCount"}).then((json) => {
                    console.log(json)
                  });
                this.setState({
                    quizEnd: true,
                    certificateDate: this.convert(Date.now())

                })
                // //////////////////////////////////////////////
                // let userStats = localStorage.getItem("userStats");
                // userStats = JSON.parse(userStats);
                // if (userStats.quizResult.hasOwnProperty("score")) {
                //     userStats.quizResult.score = userStats.quizResult.score + this.state.score;
                // }
                // else {
                //     userStats.quizResult.score = this.state.score;
                // }

                // if (userStats.quizResult.hasOwnProperty("certificate")) {
                //     userStats.quizResult.certificate = userStats.quizResult.certificate + (this.state.score > 0 ? 1 : 0);
                // }
                // else {
                //     userStats.quizResult.certificate = (this.state.score > 0 ? 1 : 0);
                // }
                // localStorage.setItem("userStats", JSON.stringify(userStats));
            }
        }
    }
    solutionHandler = (data) => {
        this.setState({
            active: true
        });
        if (data === false) {
            this.setState({
                active: false
            });
        }
    }
    storagehandler = () => {
        var eventData = JSON.parse(localStorage.getItem('events'));
        // //console.log(eventData);
        const newEventsData = this.eventStatusArray(eventData);
        localStorage.setItem("events", JSON.stringify(newEventsData));
    }
    artifactHandler = () => {
        var artifact = JSON.parse(localStorage.getItem('events'));
        this.setState({
            artifact: artifact[this.props.gameAttr.eventID][0].tile_game_info[this.props.gameAttr.gameID].game_artifact.artifact_prev,
            artifactStatus: true
        });
    }
    changeHandler = () => {
        this.setState({
            artifactStatus: false
        });
        // //console.log(this.state.active)
    }
    feedbackHandler = () => {
        this.setState({
            feedbackStatus: false
        });
        // const imageRef = useRef(null);

        // Function to get the X and Y position of the image
        // getImagePosition = () => {
        //   if (imageRef.current) {
        //     const rect = imageRef.current.getBoundingClientRect();
        //     const x = rect.left + window.scrollX;
        //     const y = rect.top + window.scrollY;
        //     //console.log(`X position: ${x}px`);
        //     //console.log(`Y position: ${y}px`);
        //   }
        // };
    }
    handleDownloadImage = () => {

        const image = new Image();
        image.crossOrigin = 'anonymous'; // Set the crossorigin attribute
        image.src = this.state.certificateImgURL; // Replace with the actual image URL

        image.onload = () => {
            const canvas = document.createElement('canvas');
            // const certimages = document.getElementById('CertificateImage');
            // canvas.width = certimages.clientWidth;
            // canvas.height = certimages.clientHeight;
            canvas.width = image.width;
            canvas.height = image.height;
            // console.log("canvaswidth: " + canvas.width + "canvasheight: " + canvas.height);
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            // ctx.font = 'bold 165px Segoe UI'; // Set the font size as needed
            ctx.fillStyle = 'white'; // Set the font size as needed            
            
            ctx.font = 'bold 110px Segoe UI'; // Set the font size as needed
            const gradex = (canvas.width - ctx.measureText(this.state.grade).width) / 2;
            const gradey = (canvas.height / 3) -50;
            ctx.fillText(this.state.grade, gradex, gradey); // Use calculated x and y for text positioning
            
            ctx.font = 'bold 215px Segoe UI'; // Set the font size as needed
            const namex = (canvas.width - ctx.measureText(this.state.text).width) / 2;
            const namey = (canvas.height / 2) + 35;
            ctx.fillText(this.state.text, namex, namey); // Use calculated x and y for text positioning
            
            ctx.font = 'bold 80px Segoe UI'; // Set the font size as needed
            const datex = 1300;
            const datey = canvas.height - 450;
            ctx.fillText(this.state.certificateDate, datex, datey); // Use calculated x and y for text positioning


            // Convert the canvas to a data URL
            const dataURL = canvas.toDataURL('image/png');

            // Create a download link and trigger the download
            const a = document.createElement('a');
            a.href = dataURL;
            a.download = 'image_with_text.png';
            a.click();
        };
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    modelsDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    modelsWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.myDivRef.current && !this.myDivRef.current.contains(event.target)) {
            this.setState({ showModal: false });
            // alert('hello');
        }
    };

    handleChange = (event) => {
        // setPrimarydataG(event.target.value);
        this.state.otherLangData.map((value, index) => {
            if (value.lang.lang_id === event.target.value) {
                // console.log(value.game)
                if (value.game.length > 0) {
                    // console.log("hello2")

                    value.game.map((x, j) => {
                        if (x.game_type_id === 3) {
                            if (this.state.currentIndex <= x.game_attr.length) {
                                this.QuizData = x.game_attr;

                                for (let i = 0; i < this.QuizData.length; i++) {
                                    this.QuizData[i].selectedAnswer = "";
                                    this.QuizData[i].score = 0;
                                    this.QuizData[i].attempt = 0;
                                }
                                this.setState({
                                    selLang: event.target.value,
                                    question: this.QuizData[this.state.currentIndex].questions,
                                    totalQuestions: this.QuizData.length,
                                    options: this.shuffle(this.QuizData[this.state.currentIndex].options),
                                    answer: this.QuizData[this.state.currentIndex].answers,
                                    value: this.QuizData[this.state.currentIndex].value,
                                    otherAttr: this.QuizData[this.state.currentIndex].otherAttr,
                                    comingSoonStatus: false,
                                    timeStatus: true,
                                })
                            }
                        }
                    })
                }
                else {
                    this.setState({
                        comingSoonStatus: true,
                        score: 0,
                        selLang: event.target.value,
                        secondsRemaining: 500,
                        timeStatus: false,
                    })
                }

            }
        })

    };

    getMonthWiseQuestions = (data) => {
        this.setState({
            monthSelectedStatus: true,
            selMonth: data,
            timeStatus: false,
        })
        if (this.state.selMonth != data) {
            this.props.eventData.tile_game_info.map((value, index) => {
                if (value.forDate === data) {
                    var otherLangData = value.multiGameData;
                    value.multiGameData.map((value, i) => {
                        if (value.lang.lang_id === this.state.selLang) {
                            if (value.game.length > 0) {
                                value.game.map((x, j) => {
                                    if (x.game_type_id === 3) {
                                        this.QuizData = x.game_attr;

                                        for (let i = 0; i < this.QuizData.length; i++) {
                                            this.QuizData[i].selectedAnswer = "";
                                            this.QuizData[i].score = 0;
                                            this.QuizData[i].attempt = 0;
                                        }
                                        this.setState({
                                            otherLangData: otherLangData,

                                            currentIndex: 0,
                                            question: this.QuizData[0].questions,
                                            totalQuestions: this.QuizData.length,
                                            options: this.shuffle(this.QuizData[0].options),
                                            // feedback: this.QuizData[this.state.currentIndex].feedback,
                                            answer: this.QuizData[0].answers,
                                            value: this.QuizData[0].value,
                                            otherAttr: this.QuizData[0].otherAttr,
                                            userAnswer: "",
                                            quizEnd: false,
                                            score: 0,
                                            nextdisabled: true,
                                            flip: false,
                                            active: false,
                                            status: false,
                                            feedbackStatus: false,
                                            feedbackUrl: "",
                                            attempt: 0,
                                            correct: 0,
                                            greenLight: false,
                                            redLight: false,
                                            optionDisabled: false,
                                            secondsRemaining: 500,
                                            showModal: false,
                                            startDate: Date.now(),
                                            comingSoonStatus: false,
                                            timeStatus: true,

                                        })

                                    }

                                })
                            }
                            else {
                                this.setState({
                                    comingSoonStatus: true,
                                    score: 0,

                                    secondsRemaining: 500,

                                })
                            }
                        }
                    })

                }
            })
        }
    }

    render() {

        const { question, userAnswer, options, currentIndex, quizEnd, flip, value, otherAttr, feedback, greenLight, redLight, optionDisabled, monthArray, monthSelectedStatus, comingSoonStatus } = this.state;
        if (quizEnd) {
            return (

                <>
                    {/* <div class="chLogoTopDiv">
                        <div className="chLogoTopHeader" >
                            <img className='chimgtopHeader' src={chLogo} alt="Logo 2" />
                            {this.state.userData.lang_id === 2 ?
                                <h2>प्रश्नोत्तरी</h2>
                                :
                                <h2>Quiz</h2>
                            }
                        </div>
                    </div> */}
                    <div className="MainContainer">
                        <Container>
                                    <h3 style={{ color: "#fff", textAlign: 'center', fontSize: '1.8rem' }}>Thank you for participating</h3>

                            {this.state.score >= this.state.scoretogcertificates ? <>
                                <div className="image-text-overlay">

                                    <div className="image-container">
                                        <img
                                            ref={this.imageRef}
                                            src={this.state.certificateImgURL}
                                            id="CertificateImage"
                                            alt="Certificate"
                                            style={{ width: "100%", height: 'auto', display: 'block' }}
                                        />
                                        {/* <div className="overlay-text" ref={this.textOverlayRef}>{this.state.text}</div> */}
                                        <div className="overlay-text1" ref={this.textOverlayRef}>{this.state.grade}</div>
                                        <div className="overlay-text" ref={this.textOverlayRef}>{this.state.text}</div>
                                        <div className="overlay-text2" ref={this.textOverlayRef}>{this.
                                            state.certificateDate}</div>
                                    </div>
                                    <div>
                                        <>
                                                    <button className="certificateButton" onClick={this.handleDownloadImage} style={{
                                                        backgroundColor: '#0054A5',
                                                        borderRadius: '0.5rem',
                                                        boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                                        color: '#fff',
                                                        padding: '0.4rem 2.3rem',
                                                        border: 'none',
                                                        margin: '0 12px 1.5rem',
                                                        fontWeight: '700',
                                                        fontSize: '1.1rem',
                                                    }}>Print</button>
                                                    <button className="certificateButton" onClick={this.handleDownloadImage} style={{
                                                        backgroundColor: '#0054A5',
                                                        borderRadius: '0.5rem',
                                                        boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                                        color: '#fff',
                                                        padding: '0.4rem 2.3rem',
                                                        border: 'none',
                                                        margin: '0 12px 1.5rem',
                                                        fontWeight: '700',
                                                        fontSize: '1.1rem',
                                                    }}>Save</button>
                                                </>
                                        <NavLink
                                            to="/activities" state={{ eventIndex: this.props.gameAttr.eventID, id: this.props.gameAttr.id }}  >
                                                    <button className="certificateButton" style={{
                                                        backgroundColor: '#0054A5',
                                                        borderRadius: '0.5rem',
                                                        boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                                        color: '#fff',
                                                        padding: '0.4rem 2.3rem',
                                                        border: 'none',
                                                        margin: '0 12px 1.5rem',
                                                        fontWeight: '700',
                                                        fontSize: '1.1rem',
                                                    }}>Go To HomePage</button>
                                        </NavLink>
                                    </div>
                                </div>
                            </> :
                                <>
                                    <div style={{textAlign: 'center'}}>
                                                <h1 style={{ color: '#000', margin: '5% auto', color: 'white' }}>Your Score is not eligible to get a certificate</h1>
                                                <NavLink
                                            to="/activities" state={{ eventIndex: this.props.gameAttr.eventID, id: this.props.gameAttr.id }}  >
                                                    <button className="certificateButton" style={{
                                                        backgroundColor: '#0054A5',
                                                        borderRadius: '0.5rem',
                                                        boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                                        color: '#fff',
                                                        padding: '0.4rem 2.3rem',
                                                        border: 'none',
                                                        margin: '0 12px 1.5rem',
                                                        fontWeight: '700',
                                                        fontSize: '1.1rem',
                                                    }}>Go To HomePage</button>
                                        </NavLink>
                                    </div>
                                </>}
                            <div>
                                {
                                    this.state.active &&
                                    <QuizSolution show={this.state.active} data={this.solutionHandler} value={this.QuizData} score={this.state.score} />
                                }
                            </div>
                        </Container>
                    </div>

                </>
            )
        }

        return (
            <>
                <div style={{ margin: '2% auto 0' }}>
                    <button
                        style={{
                            backgroundColor: '#0054A5',
                            boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                            color: '#fff',
                            padding: '0.5rem 2.3rem',
                            border: 'none',
                            borderRadius: '0.6rem',
                            fontWeight: '700',
                            fontSize: '1.3rem',
                            margin: '0 auto 1.7rem',
                        }}

                        disabled
                    >QUIZ</button>
                </div>
                <div class="chLogoTopDiv">
                    <div className="chLogoTopHeader" >
                    </div>
                </div>
                <div className="full" style={{ margin: '2% auto' }}>
                    <Container fluid className="containerfluidDiv" style={{ backgroundColor: 'rgba(40, 83, 118, 0.5)', color: '#000', borderRadius: '0.625rem', border: '1px solid rgba(0,0,0,0.1)' }}>
                        <Grid container style={{ alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid rgba(0,0,0,0.2)' }}>

                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12} style={{}}>
                                <div>
                                    <nav>
                                        <ul className="monthUl">
                                            {monthArray.map((data, index) => (
                                                <li className={`monthLi ${this.state.selMonth == data.value ? "monthSelected" : ""}`} style={{}} onClick={() => this.getMonthWiseQuestions(data.value)}>{data.name}</li>
                                            )

                                            )}
                                        </ul>
                                    </nav>
                                </div>
                            </Grid>

                        </Grid>
                        <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>

                            <Grid className="resultComponent" item xs={12} sm={12} md={12} xl={3.2} lg={3.2} style={{}}>
                                <Grid container>
                                    <Grid item xs={6} sm={6} md={6} xl={12} lg={12} style={{ padding: 10 }}>
                                        <FormControl style={{ width: '100%', padding: '' }}>
                                            <InputLabel id="demo-simple-select-autowidth-label" style={{
                                                color: '#000',
                                                backgroundColor: '#fff', border: 'none', borderRadius: '15px', padding: '5px 10px',
                                            }}>Try this quiz in other language</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                value={this.state.selLang}
                                                onChange={this.handleChange}
                                                autoWidth
                                                label="Try this quiz in other language"
                                                style={{ minWidth: '100px', fontSize: '1.5rem', color: 'white', color: 'rgb(255, 255, 255)', backgroundColor: '#0054A5' }}
                                                MenuProps={{ PaperProps: { style: { backgroundColor: '#0054A5', color: '#fff' } } }}
                                            >

                                                {this.state.langData.map(data => (
                                                    <MenuItem key={data.lang_id} value={data.lang_id} style={{ width: "100% !important", textTransform: 'none',color:'#fff' }}>
                                                        {data.lang_desc}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} xl={12} lg={12} style={{}}>
                                        <div className="rules" style={{ backgroundColor: 'transparent' }}>
                                            <div className="counters" style={{}}>
                                                        <h1 style={{ margin: 0 }}>Timer</h1>
                                                <h1 style={{ margin: 0, padding: '5px 15px', border: '1px solid #000', color: '#8bc53f', }}>{this.state.timeStatus ? this.state.secondsRemaining : 500}</h1>

                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} xl={12} lg={12} style={{}}>
                                        <div className="rules" style={{ backgroundColor: 'transparent' }}>
                                            <div className="counters" style={{}}>
                                                        <h1 style={{ margin: 0 }}>Score</h1>
                                                <h1 style={{ margin: 0, padding: '5px 15px', border: '1px solid #000', color: '#8bc53f', }}>{this.state.score}</h1>

                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {comingSoonStatus ?
                                <Grid item xs={12} sm={12} md={12} xl={8} lg={8} style={{}}>
                                    <div style={{ margin: '1% auto', backgroundColor: '#fbf8f4', color: '#000', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.25) 5px 5px 10px', height: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundImage: `url('https://ik.imagekit.io/singh/BuddhiyogaMobileApplication/congratps_kxDwgdY2Qy.png?updatedAt=1696939775009')`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                        <h1 style={{ fontWeight: '500', fontSize: 50, color: 'rgb(139, 197, 63)' }}>Coming Soon</h1>
                                    </div>
                                </Grid>


                                :
                                <Grid item xs={12} sm={12} md={12} xl={8} lg={8} style={{}}>


                                    <div style={{ position: "relative", color: '#000' }} ref={this.myDivRef}>

                                        {this.state.showModal ?

                                            <div className={`modal show modals`} style={{}} >
                                                <div className="modal-content" style={{borderRadius: 10}}>
                                                    {greenLight && <ConfettiFunc />}
                                                    {/* <span className="close">greenLight?<><img className="modelscongratpsimg" src={congratps} /></>:<></>} */}
                                                    <span className="" onClick={this.nextQuestionHanlder}>
                                                        <img className="modelsimg" src={greenLight ? correct : incorrect} /><span style={{ position: 'absolute', right: 10 }} className="closeIcon">&times;</span>
                                                    </span>
                                                    {greenLight ? <>
                                                        {
                                                            this.state.userData.lang_id === 2 ?
                                                                <h2 style={{}}>यह सही है!</h2>
                                                                :
                                                                <h2 style={{}}>That's Correct!</h2>
                                                        }
                                                        {/* <h2 style={{}}>That's Correct!</h2> */}
                                                    </> : <></>}
                                                    <p className="modelspara">{this.state.feedbackUrl}</p>

                                                </div>
                                            </div>
                                            :
                                            <>


                                                <div className={`cardd ${flip ? 'flip' : ''}`} >


                                                    <div className={`quiz ${flip ? 'flip1' : ''}`}>
                                                        <h2 className="question" style={{ textTransform: 'initial' }}>{question}</h2>
                                                        <div className="otherAttr">
                                                            {
                                                                value == 0 &&
                                                                <img className="otherAttrImage" style={{ width: "75%", maxWidth: "580px" }} src={IP + "getImage/?imgName=" + otherAttr} />
                                                            }
                                                        </div>
                                                        <Container style={{ padding: 0, marginTop: '75px' }}>
                                                            <Row style={{ justifyContent: 'space-between', width: '100%', margin: '0 auto' }}>
                                                                {
                                                                    options.map((option, i) =>

                                                                    (
                                                                        <Col lg={6} xs={12} md={6} sm={12} className="optionsCol" key={i}>
                                                                            <div onClick={() => this.checkAnswer(option, i)} style={{ textTransform: 'initial', cursor: "pointer" }} key={i} className={`options ${userAnswer === option || this.QuizData[currentIndex].selectedAnswer === option ? greenLight ? "selected" : redLight ? "selectedfalse" : "" : null} `}
                                                                            >

                                                                                <span style={{
                                                                                    padding: '0 6.5%', marginRight: '5px', fontSize: '45px', borderRadius: '15px', backgroundColor:
                                                                                        i == 0 ? '#5190f5' : i == 1 ? '#ff879f' : i == 2 ? '#e488e4' : i == 3 ? '#ffc57b' : '#000', alignItems: 'center', display: 'flex', flexDirection: 'row'
                                                                                }}>
                                                                                    <p style={{ margin: '0px' }}>
                                                                                        {
                                                                                            i == 0 ?
                                                                                                'A'
                                                                                                :
                                                                                                i == 1 ?
                                                                                                    'B'
                                                                                                    :
                                                                                                    i == 2 ?
                                                                                                        'C'
                                                                                                        :
                                                                                                        i == 3 ?
                                                                                                            'D'
                                                                                                            :
                                                                                                            <></>
                                                                                        }
                                                                                    </p>
                                                                                </span>

                                                                                <span style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}> <p style={{ margin: '0px' }}>{option}</p></span>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                    )

                                                                }
                                                            </Row>
                                                        </Container>


                                                    </div>

                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div style={{ margin: "10px auto 0", display: comingSoonStatus ? 'none' : 'block' }}>
                                        {(currentIndex < this.QuizData.length - 1) ?
                                            (
                                                (<button onClick={this.nextQuestionHanlder} style={{
                                                    backgroundColor: '#0054A5',
                                                    borderRadius: '0.5rem',
                                                    boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                                    color: '#fff',
                                                    padding: '0.5rem 2.3rem',
                                                    border: 'none',
                                                    margin: '0 auto 1.5rem',
                                                    fontWeight: '700',
                                                    fontSize: '1.1rem',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>Next
                                                </button>)
                                            )
                                            : <></>}

                                        {((currentIndex === this.QuizData.length - 1 || this.state.score >= this.state.scoretogcertificates)) &&
                                            (
                                                    <button onClick={this.finishHandler} className="certificateButton" style={{
                                                        backgroundColor: '#0054A5',
                                                        borderRadius: '0.5rem',
                                                        boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                                                        color: '#fff',
                                                        padding: '0.3rem 2.3rem',
                                                        border: 'none',
                                                        margin: '0 12px 1.5rem',
                                                        fontWeight: '700',
                                                        fontSize: '1.1rem',
                                                    }}>Finish</button>

                                            )
                                        }
                                        
                                    </div>

                                </Grid>
                            }


                        </Grid>
                    </Container>
                </div >
            </>
        );

    }

}

export default Quiz;
