import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import TopHeader from '../components/TopHeader';
import landingGif from '../resources/assets/assets/gif.gif';
import { BUCKET_URL } from '../connection';
// import bottomImage from '../resources/assets/EXPORTS/LANDING PAGE/1X/Asset 5.png';
import { NavLink } from 'react-router-dom';
import bottomImage from '../resources/assets/EXPORTS/Asset 8@4x.webp';
import EventDataParser from '../Services/EventDataParser';
import userService from '../Services/userService';

const theme = createTheme();

const HomePageNew = () => {

    const moonImage =BUCKET_URL+"moon.webp";

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    const [isSmallDesktop, setIsSmallDesktop] = useState(window.innerWidth > 600 && window.innerWidth <= 1024);
    const [gameData, setGameData] = useState([]);   

    React.useEffect(() => {
        if (gameData.length <= 0)
          getTileData();
      }, []);
    
     const [userCount, setUserCount] = React.useState('Loading..');

  React.useEffect(() => {
	  if(userCount === 'Loading..')
	  {
		  console.log("Counting....");
  		 getUserCount();
	  }
  }, []);

  const getUserCount = async () => {
     userService.getInstance().postUserCount().then((json) => {
      if (json.status === 200) {
        setUserCount(json.data);
        console.log(json.data);
      } else {
        setUserCount(100000);
      }
    });
  };


      async function getTileData() {
        // console.log("getTileData")
        let data = await EventDataParser();
        setGameData(data);
      }

  //    React.useEffect(async () => {
    //    await getUserCount();
     // }, [])
    
   //   const getUserCount = async () => {
    //    await userService.getInstance().postUserCount().then((json) => {
     //     console.log(json);
      //    if (json.status === 200) {
       //   }
        //  else {
         // }
    
   //     })
    //  }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
            setIsSmallDesktop(window.innerWidth > 600 && window.innerWidth <= 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const bottomImageStyle = {
        position: isMobile ? 'relative' : 'fixed',
        bottom: isMobile ? 'auto' : '10px',
        left: isMobile ? 'auto' : '50%',
        transform: isMobile ? 'none' : 'translateX(-50%)',
        maxWidth: isMobile ? '100%' : isSmallDesktop ? '45%' : '58%',
        height: 'auto',
        width: isMobile ? '35%' : '11%', // Ensure the image scales proportionally
        zIndex: 1000,
        margin: 0,
    };

    const textStyle = {
        position: 'fixed',
        bottom: '7em',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1001,
        color: 'white',
        margin: 0,
    };

    const landingGifStyle = {
        maxWidth: isMobile ? '100%' : isSmallDesktop ? '70%' : '60%',
        width: isMobile ? '70%' : '80%',
        height: 'auto',
        margin: '0 auto',
        transform: isMobile ? 'translateY(-17%)' : 'translateY(-12%)',
    };
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100vh',
        overflow: 'hidden',
        padding: 0,
        margin: 0,
        position: 'relative',
    };

    return (
        <>
            <TopHeader to="/" />
            {/* <div className="bgOverlayImage" style={{display: isMobile ? 'block' : 'none' }}>
                    <img src={moonImage} alt="" className="moonImage" />
            </div> */}
            <ThemeProvider theme={theme}>
                <div style={containerStyle}>
                    <img src={BUCKET_URL+"gif.gif"} alt="Center GIF" style={landingGifStyle} />

                </div>
            </ThemeProvider>

            <div style={{ width: '100%', margin: '0 auto' }}>
                <NavLink to="/activities" style={{ display: 'flex', justifyContent: 'center', }}>
                    <img src={bottomImage} alt="Bottom Image" style={bottomImageStyle} />
                    {/* <h6 style={textStyle}>Click Here</h6> */}
                </NavLink>
            </div>

        </>
    );
};

export default HomePageNew;
