import React from "react";
import './Home.css'
import Footer from "./Footer";
import Dashboard from "../components/Dashboard";
import TopHeader from "../components/TopHeader";

const Home = (props) => {
  return (
    <>
      <TopHeader to="/activities" />
      <Dashboard />
      <Footer />
    </>)
}

export default Home;