import React, { useState, useEffect } from "react";
import Tile from "./Tile";
import { TILE_COUNT, GRID_SIZE, BOARD_SIZE } from "./constants"
import { canSwap, shuffle, swap, isSolved } from "./helpers"
import { Modal, Button } from 'react-bootstrap';
// import {IP} from '../../connection';

import './picture.css'
import './Board.css'

// import  chimageicons  from '../../resources/assets/assets/i4.png';

function Board({ imgUrl, levelClick, handleImageChange, langID }) {

  GRID_SIZE = levelClick;
  TILE_COUNT = GRID_SIZE * GRID_SIZE;
  BOARD_SIZE = 500;

  const [tiles, setTiles] = useState([...Array(TILE_COUNT).keys()]);
  const [isStarted, setIsStarted] = useState(false);
  const [imageStatus, setImageStatus] = useState(false);
  // //console.log(imgUrl)

  const shuffleTiles = () => {
    const shuffledTiles = shuffle(tiles)
    setTiles(shuffledTiles);
  }

  const swapTiles = (tileIndex) => {
    if (canSwap(tileIndex, tiles.indexOf(tiles.length - 1))) {
      const swappedTiles = swap(tiles, tileIndex, tiles.indexOf(tiles.length - 1))
      setTiles(swappedTiles)
    }
  }

  const handleTileClick = (index) => {
    swapTiles(index)
  }

  const handleShuffleClick = () => {
    shuffleTiles()
  }

  const handleStartClick = () => {
    shuffleTiles()
    setIsStarted(true)
  }
  useEffect(() => {
    handleStartClick();
  }, [imgUrl]);



  const pieceWidth = Math.round(BOARD_SIZE / GRID_SIZE);
  const pieceHeight = Math.round(BOARD_SIZE / GRID_SIZE);
  // const style = {
  //   width: BOARD_SIZE,
  //   height: BOARD_SIZE,
  //   // zIndex: '1',
  // };
  // const hasWon = isSolved(tiles)
  const handleImageClick = () => {
    // alert("hello");
    setImageStatus(!imageStatus);
  }



  return (

    <>
      {
        imageStatus &&
        <div style={{ zIndex: 1 }}>
          <Modal
            show={true}
            onHide={() => handleImageClick()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {/* Artifact Collection */}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{textAlign: 'center'}}>
                {/* <h3>{imgUrl}</h3> */}
                <img style={{ width: "55%" }} src={imgUrl}></img>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => handleImageClick()}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>

      }
      {/* <h3 style={{color:"black"}}>Become a Picture Detective</h3> */}
      <div style={{ width: BOARD_SIZE, height: BOARD_SIZE,}} className="board">
        {tiles.map((tile, index) => (
          <Tile
            key={tile}
            index={index}
            imgUrl={imgUrl}
            tile={tile}
            width={pieceWidth}
            height={pieceHeight}
            TILE_COUNT={TILE_COUNT}
            GRID_SIZE={GRID_SIZE}
            BOARD_SIZE={BOARD_SIZE}
            handleTileClick={handleTileClick}
          />
        )
        )}
      </div>
      {/* {hasWon && isStarted && <div>Puzzle solved 🧠 🎉</div>} */}
      <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between' }}>{!isStarted ?
        (<button onClick={() => handleImageClick()} style={{ borderRadius: "10px", padding: "1% 2%", border: "none", backgroundColor: "rgb(155, 35, 76)", color: "white", margin: '0 5px', zIndex: '1' }}><span>Start game</span></button>) :
        (
          <>
            {
              langID === 2 ? <>
                <button onClick={() => handleImageClick()} style={{
                  borderRadius: "10px", padding: "2% 3%", border: "none", backgroundColor: '#0054A5',
                  boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px', color: "white", margin: '20px auto 0px', zIndex: '1'
                }}><span>वास्तविक प्रतिबिंब</span></button>
                <span onClick={handleImageChange} style={{
                  borderRadius: "10px", padding: "2% 3%", border: "none", backgroundColor: '#0054A5',
                  boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px', color: "white", margin: '20px auto 0px', cursor: 'pointer', zIndex: '1'
                }}>चित्र को बदलें</span>
              </>
                :
                <>
                  <button onClick={() => handleImageClick()} style={{
                    borderRadius: "10px", padding: "2% 3%", border: "none", backgroundColor: '#0054A5',
                    boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px', color: "white", margin: '20px auto 0px', zIndex: '1'
                  }}><span>Original Image</span></button>
                  <span onClick={handleImageChange} style={{
                    borderRadius: "10px", padding: "2% 3%", border: "none", backgroundColor: '#0054A5',
                    boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px', color: "white", margin: '20px auto 0px', cursor: 'pointer', zIndex: '1'
                  }}>Change Image</span>
                </>}
            {/* <button onClick={() => handleImageClick()} style={{ borderRadius: "10px", padding: "1% 2%", border: "none", backgroundColor: "rgb(155, 35, 76)", color: "white", margin: '0 5px' }}><span>Original Image</span></button> */}
          </>)}

        {/* <button onClick={() => handleShuffleClick()} style={{ borderRadius: "10px", padding: "1% 2%", border: "none", backgroundColor: "rgb(155, 35, 76)", color: "white", margin: '0 5px'}}><span>Restart game</span></button> */}
      </div>
    </>
  );
}

export default Board;