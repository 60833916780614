import React from 'react';
import Container from 'react-bootstrap/Container';
import Grid from '@mui/material/Grid';
import './YoutubeVideos.css';
import snews2 from '../resources/assets/assets/2.jpeg';
import snews1 from '../resources/assets/assets/1.jpeg';
import snews3 from '../resources/assets/assets/3.jpeg';
import newsImg from '../resources/assets/EXPORTS/IMAGE GALLERY PAGE/WEBP/4x/Asset 12@4x.webp';
import youtubeImg from '../resources/assets/EXPORTS/IMAGE GALLERY PAGE/WEBP/4x/Asset 10@4x.webp';
import backgroundImg from '../resources/assets/EXPORTS/IMAGE GALLERY PAGE/SVG/SVG/Asset 14.svg';
import zIndex from '@mui/material/styles/zIndex';

export default function YouTubeVideos() {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const StyledVideoMain = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  };
  const StyledVideo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    position: 'relative',
  };

  const container = {
    margin: '0 auto',
    padding: 0,
    width: '90%',
  }

  const responsiveIframe = {
    // position: 'absolute',
    // top: '0',
    // left: '0',
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: '20px'
  };

  const descriptionStyle = {
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: '20px',
    padding: '20px',
    boxSizing: 'border-box',
  };

  const StyledImage = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    overflow: 'hidden',
    marginBottom: '10px',
  };

  const imageContainerStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '10px',
    padding: '10px',
    boxSizing: 'border-box',
  };

  const bentoContainerStyle = {
    backgroundColor: 'rgba(40, 83, 118, 0.5)',
    borderRadius: '20px',
    padding: '2rem',
    marginTop: '3rem',
  };

  const youtubeImgStyle = isMobile
    ? {
      position: 'absolute',
      zIndex: 1,
      top: '10px',
      left: '10px',
      width: '20%',
    }
    : {
      position: 'absolute',
      zIndex: 1,
      top: '-2rem',
      left: '-2rem',
      width: '9%',
    };

  const newsImgStyle = isMobile
    ? {
      position: 'absolute',
      zIndex: 1,
      top: '10px',
      left: '10px',
      width: '20%',
    }
    : {
      position: 'absolute',
      zIndex: 1,
      left: '-2rem',
      top: '-2rem',
      width: '9%',
    };

  const additionalBackgroundStyle = {
    // backgroundImage: `url(${backgroundImg})`,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
    // backgroundRepeat: 'no-repeat',
    zIndex: '-1',
  };
  const backgroundDoodle = {
    width: '155%',
  };

  const images = [
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/1_I_drjHT7oq.jpeg?updatedAt=1700123684910',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/2_sbRXnH-l7A.jpeg?updatedAt=1700123686282',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/3_vJKPRxR_lY.jpeg?updatedAt=1700123705276',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/4_v37UqOFp7i.jpeg?updatedAt=1700123711666',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/5_F738CPV3k.jpeg?updatedAt=1700123713717',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/6_5rVn35RFL.jpeg?updatedAt=1700123714644',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/7_Uma1LljA4.jpeg?updatedAt=1700123714095',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/8_r2TIKHcvzE.jpeg?updatedAt=1700123716897',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/9_uNoaSp5yp.jpeg?updatedAt=1700123718426',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/10_tCPCyML5n.jpeg?updatedAt=1700123682785',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/11_Eex3QBqbi.jpeg?updatedAt=1700123685495',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/12_B6zu67PN5.jpeg?updatedAt=1700123686170',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/13_35XexEIB4.jpeg?updatedAt=1700123679044',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/14_25nx7cn7-.jpeg?updatedAt=1700123683451',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/15_gigAQgof6.jpeg?updatedAt=1700123684145',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/16_UAUdI2R3m.jpeg?updatedAt=1700123676451',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/17_cGlS715af6.jpeg?updatedAt=1700123695200',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/18_wlhw_7uaC4.jpeg?updatedAt=1700123685451',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/19_c4X4IClBs.jpeg?updatedAt=1700123695196',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/20_CM20Wv-pQ.jpeg?updatedAt=1700123697701',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/21_XAOwhHfiv.jpeg?updatedAt=1700123702999',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/22_RpW_OgVnyI.jpeg?updatedAt=1700123697642',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/23_GrlMeTEb0.jpeg?updatedAt=1700123699163',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/24_GjKCZ2hK0W.jpeg?updatedAt=1700123704089',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/25_uILZTeX2Q.jpeg?updatedAt=1700123713752',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/26_FSqvQihXxI.jpeg?updatedAt=1700123703575',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/27_4Hhkm0qzA.jpeg?updatedAt=1700123701636',
    'https://ik.imagekit.io/singh/bhashasangam/ncertbharatonthemoonsimagesgallery/28_8siUdT7x22.jpeg?updatedAt=1700123718430',


    // Add more image URLs as needed
  ];

  return (
    <Container style={container}>
      <div style={additionalBackgroundStyle}>
        <img src={backgroundImg} alt="" style={backgroundDoodle} />
      </div>

      <Grid container spacing={4} justifyContent={'center'} style={{ width: '100%', position: 'relative', backgroundColor: 'rgba(40, 83, 118, 0.5)', color: '#fff', borderRadius: 40, border: '1px solid rgba(0,0,0,0.1)', padding: '3rem', margin: '0 0 5rem' }}>
        <img src={youtubeImg} alt='Youtube Image' style={youtubeImgStyle} />
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={descriptionStyle} className='descriptionGrid' >
          <p style={{ textAlign: 'left' }}>
            {(
              <>
                Chandrayaan -3 and 23 August 2023 is etched in history as a glorious day for Indians. Our Prime Minister, Shri Narendra Modi,
                declared 23 August to be celebrated as National Space Day – marking the significance of this milestone event.<br /><br />
                NCERT under the aegis of DoSEL, MoE, Govt. of India aims to inspire the young Indians in Science, Technology,
                Engineering, Art and Mathematics using activities based around the legacy of Chandrayaan-3.<br /><br />
                Every month, the new creative activities and initiatives, will aim to promote skill sets that foster innovation and critical thinking in young minds.<br /><br />
                We hope teachers, parents and students use these resources to create enriching learning experiences within the classroom and beyond…
              </>
            )}
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingTop: 0 }} className='iframeGrid' >
          <div style={StyledVideoMain}>
            <iframe
              className='videoFrames'
              src="https://www.youtube.com/embed/00Sx6WIMdQE?si=H2tYakTmxOAB8OL4"
              title="Virtual tour of Chandrayaan 3"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
              style={responsiveIframe}
            />
          </div>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '2rem' }} className='iframeGrid2' >
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div style={descriptionStyle}>
              <div style={StyledVideo}>
                <iframe
                  className='launchvideoFrames'
                  src="https://www.youtube.com/embed/SZIHQvsEKcg?si=Wa-iqdD_Z0olMaSI"
                  title="Virtual tour of Chandrayaan 3"
                  frameBorder="0"
                  style={responsiveIframe}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div style={descriptionStyle}>
              <div style={StyledVideo}>
                <iframe
                  className='launchvideoFrames'
                  src="https://www.youtube.com/embed/rm6yz1oF1Fo?si=GTI0wEoJwOeMlsai"
                  title="Virtual tour of Chandrayaan 3"
                  frameBorder="0"
                  style={responsiveIframe}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div style={descriptionStyle}>
              <div style={StyledVideo}>
                <iframe
                  className='launchvideoFrames'
                  src="https://www.youtube.com/embed/eRrCdrEWZUc?si=lVQ7DaPIKOor1-eZ"
                  title="Virtual tour of Chandrayaan 3"
                  frameBorder="0"
                  style={responsiveIframe}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ width: '100%', position: 'relative', backgroundColor: 'rgba(40, 83, 118, 0.5)', color: '#fff', borderRadius: 40, border: '1px solid rgba(0,0,0,0.1)', padding: '3rem' }}>
        <img src={newsImg} alt='News Image' style={newsImgStyle} />
        <Grid container spacing={4} style={{ width: '100%', margin: 0, }}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ padding: '10px' }}>
            <div style={imageContainerStyle}>
              <div style={StyledImage}>
                <img style={{ width: '100%' }} src={snews2} alt="snews2" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ padding: '10px' }}>
            <div style={imageContainerStyle}>
              <div style={StyledImage}>
                <img style={{ width: '100%' }} src={snews1} alt="snews1" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ padding: '10px' }}>
            <div style={imageContainerStyle}>
              <div style={StyledImage}>
                <img style={{ width: '100%' }} src={snews3} alt="snews3" />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* Bento Grid Section */}
      <Grid container justifyContent="center" spacing={4} style={bentoContainerStyle}>
        <Grid container spacing={4} style={{ marginLeft: 0 }}>
          {images.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index} style={{ padding: '10px' }}>
              <div style={{ backgroundColor: 'rgba(255,255,255,0.3)', borderRadius: '10px', overflow: 'hidden' }}>
                <img style={{ width: '100%' }} src={image} alt={`Image ${index + 1}`} />
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}
