import React from "react";
import { useNavigate } from 'react-router-dom';

// import CardBoard from "../components/CardBoard";
import stylesHeader from "../screens/Header.module.css";
import logo from "../resources/assets/logo.png";
import tagline from "../resources/assets/kholkhel_tag.png";
import Stats from '../components/stats';
import { BiRefresh } from "react-icons/bi";
import Artifact from '../components/artifact'
import { NavLink } from 'react-router-dom';
import { Button, Col, Row } from "react-bootstrap";
import Settings from "../components/settings";
import Grade from "../components/Grade";
import TopHeader from "../components/TopHeader";
import Information from "../components/information";
import { IoMdLogOut } from "react-icons/io";
import { Grid, Paper } from '@mui/material';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Logo1 from '../resources/assets/assets/i3.png';
import Logo2 from '../resources/assets/home.png';
import saveUserDataToDB from '../Services/saveUserData';


import { createTheme, ThemeProvider } from '@mui/material/styles';


function Header() {


    const theme = createTheme();
    const history = useNavigate();

    const handleSubmit = () => {
        // window.localStorage.clear();
        localStorage.removeItem('userID');
        localStorage.removeItem('events');
        localStorage.removeItem('userCount');
        localStorage.removeItem('userSession');
        saveUserDataToDB();
        history("/homeNew");
    }
    const eventHandlers = () => {
        localStorage.removeItem('events')
        // history("/");
        window.location.reload();

    }

    const [userData, setUserData] = React.useState([]);

    React.useEffect(async () => {

        if (userData.length === 0) { await getUserInformation() };

    });

    const getUserInformation = async () => {
        const userID = localStorage.getItem("userID");
        // //console.log(JSON.parse(userID));
        //console.log(typeof(JSON.parse(userID)));
        if (userID === null) {

        }
        else {
            //console.log("hello");
            setUserData(JSON.parse(userID));
        }

    }


    return (
        <>

            <TopHeader />
            {/* <section style={{ paddingTop: 20 }}> */}
            {/* <Container className={stylesHeader.containerDiv} style={{}}> */}

            {/* <div className={stylesHeader.containerDiv} style={{}}> */}
            {/* <Grid container justifyContent="center" alignItems="center"> */}
            {/* <Grid item xs={0} sm={0} md={3}> */}
            {/* <Information /> */}
            {/* <Button onClick={()=>{eventHandlers()}}><BiRefresh/></Button> */}
            {/* <Artifact/> */}

            {/* </Grid> */}
            {/* <Grid item xs={7} sm={7} md={6}> */}

            {/* <Grade /> */}
            {/* </Grid> */}
            {/* <Grid item xs={5} sm={5} md={3} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}> */}
            {/* <Settings />
            
         <Stats /> */}
            {/* <NavLink */}
            {/* <img style={{ maxWidth: '25%', height: 'auto' }} src={Logo2} alt="Logo 3" />
                            </NavLink> */}
            {/* <Button onClick={() => handleSubmit()} style={{ margin: '0px', alignItems: 'center', textAlign: 'center', display: 'inline-flex', justifyContent: 'center', padding: '5px 10px', fontSize: '15px !important', fontWeight: '400' }}> */}
            {/* <img style={{maxWidth: '65%', height: 'auto'}} src={Logo1} alt="Logo 3" /> */}
            {/* {userData.lang_id === 2 ? <>
                                    लॉगआउट</> : <>
                                    Logout</>}
                            </Button>
                        </Grid>

                    </Grid> */}
            {/* </div> */}
            {/* </Container>
            </section> */}



            {/* <div className={stylesHeader.linetag} style={{maxWidth: "832px"}}><img src={tagline} alt=""/></div> */}


        </>

    );

    //     return (
    //        <>

    //         <TopHeader />
    //         <section style={{ backgroundColor: '#981F4D' }}>
    //         <Container style={{ width: '55%' }}>
    //         <Grid container spacing={2} justifyContent="center" alignItems="center">
    //         <Grid item xs={12} sm={4} md={3}>
    //             {/* <Information /> */}
    //             <Button onClick={() => { eventHandlers() }}><BiRefresh /></Button>
    //                 <Artifact />
    //                 </Grid>
    //                 <Grid item xs={12} sm={4} md={4}>

    //                 <Grade />
    //                 </Grid>
    //                 <Grid item xs={12} sm={4} md={3}>
    //             <Settings />

    //             <Stats />
    //             <Button onClick={() => handleSubmit()}>
    //             <IoMdLogOut />
    //             </Button>
    //             </Grid>

    //             </Grid>
    // </Container>
    //         </section>



    // {/* <div className={stylesHeader.linetag} style={{maxWidth: "832px"}}><img src={tagline} alt=""/></div> */}


    //         </>

    //     );
}

export default Header;
