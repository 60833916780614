import GetEventRecords from './getEventRecords';
import GameData from './GameData.json'

export default async function EventDataParser(){
  const eventData = GameData.data[0];
//   console.log(eventData)
    const resultData =await eventStatusArray(eventData);
    console.log(resultData);
    return resultData;
  // });
// }
}

async function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }


async function eventStatusArray(data) {
    console.log(data)
    const statusData = [];
    for (let i = 0; i < data.length; i++) {
      var gameData = [];
      if(data[i].event_type_id==2){
        var newGameData={};
        for (let j = 0; j < data[i].tile_gameData.length; j++) {
          var arr=[];
          for(let k=0;k<data[i].tile_gameData[j].multiGameData.length;k++)
        {
          newGameData={lang:data[i].tile_gameData[j].multiGameData[k][0],game:data[i].tile_gameData[j].multiGameData[k][2],selected:false};
          arr.push(newGameData);
        }
          gameData.push({forDate:data[i].tile_gameData[j].forDate, multiGameData:arr,game_status:0});
        }
        statusData.push([{ tile_desc: data[i].tile_desc, tile_background:data[i].tile_background,tile_end_date: await convert(data[i].tile_end_date), tile_artifact_info: data[i].tile_artifact_info, tile_start_date: await convert(data[i].tile_start_date), tile_id: data[i].tile_id,event_type_id: data[i].event_type_id,tile_game_info: gameData,tile_grade_id:data[i].tile_grade_id }])
      }
    }
    console.log(statusData)
    return statusData;
  }