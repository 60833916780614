import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './screens/Home';
import HomePageNew from './screens/homeNew';
import Activities from './screens/Activities';
import InspiringStories from './components/InspiringStories';
import ArtConnect from './components/ArtConnect';
import ImplementQuiz from './components/ImplementQuiz'
import PicturePuzzle from './components/puzzlegame/ImplementPicturePuzzle';
import './resources/assets/Fonts/K501.TTF'
import UsersStats from './screens/UserStats';
import Novels from './components/Novels';
import ImplementPuzzle from './components/dragNdrop/ImplementDND';
import ParticleBackground from './components/ParticleBackground';
import { pdfjs } from 'react-pdf';
import ISRO from './components/ISRO';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<MainContent />} />
      </Routes>
    </BrowserRouter>
  );
}

function MainContent() {
  return (
    <div style={{ 
      position: 'relative', 
      minHeight: '100vh', 
      overflow: 'hidden', 
      flexDirection: 'column', 
      display: 'flex', 
      justifyContent: 'space-between' }}>
      <ParticleBackground />
      <Routes>
        <Route path='/' element={<HomePageNew />} />
        <Route path='/activities' element={<Activities />} />
        <Route path='/launchevents' element={<Home />} />
        <Route path='/eresources' element={<ISRO />} />        
        <Route path='/inspiringstories' element={<InspiringStories />} />
        <Route path='/novels' element={<Novels />} />
        <Route path='/artconnect' element={<ArtConnect />} />
        <Route path='/puzzle' element={<ImplementPuzzle />} />
        <Route path='/quiz' element={<ImplementQuiz />} />
        <Route path='/picturePuzzle' element={<PicturePuzzle />} />
        <Route path='/userstats' element={<UsersStats />} />
      </Routes>
    </div>
  );
}

export default App;
