import modLogo from '../resources/assets/EXPORTS/MODULES/4x/Asset 1@4x.webp';
import modG20Logo from '../resources/assets/EXPORTS/MODULES/4x/G20@2x.webp';
import modNSLogo from '../resources/assets/EXPORTS/MODULES/4x/NAARI SGAKTI@2x.webp';
import modVBLogo from '../resources/assets/EXPORTS/MODULES/4x/VIKSIT BHARAT@2x.webp';
export const contentArray = {
  "Chandrayan Utsav": [

    {
      Language: "English",
      themeImage: modLogo,
      ImageUrl: [
        "/cu/English/English1/docs/English1.pdf_1_thumb.jpg",
          "/cu/English/English2/docs/English2.pdf_1_thumb.jpg",
          "/cu/English/English3/docs/English3.pdf_1_thumb.jpg",
          "/cu/English/English4/docs/English4.pdf_1_thumb.jpg",
          "/cu/English/English5/docs/English5.pdf_1_thumb.jpg",
          "/cu/English/English6/docs/English6.pdf_1_thumb.jpg",
          "/cu/English/English7/docs/English7.pdf_1_thumb.jpg",
          "/cu/English/English8/docs/English8.pdf_1_thumb.jpg",
          "/cu/English/English9/docs/English9.pdf_1_thumb.jpg",
          "/cu/English/English10/docs/English10.pdf_1_thumb.jpg",
      ],
      HyperLink:
        [
          "/cu/English/English1",
          "/cu/English/English2",
          "/cu/English/English3",
          "/cu/English/English4",
          "/cu/English/English5",
          "/cu/English/English6",
          "/cu/English/English7",
          "/cu/English/English8",
          "/cu/English/English9",
          "/cu/English/English10",
        ]
    },
    {
      Language: "Hindi", ImageUrl: [
      "/cu/Hindi/Hindi1/docs/Hindi1.pdf_1_thumb.jpg",
          "/cu/Hindi/Hindi2/docs/Hindi2.pdf_1_thumb.jpg",
          "/cu/Hindi/Hindi3/docs/Hindi3.pdf_1_thumb.jpg",
          "/cu/Hindi/Hindi4/docs/Hindi4.pdf_1_thumb.jpg",
          "/cu/Hindi/Hindi5/docs/Hindi5.pdf_1_thumb.jpg",
          "/cu/Hindi/Hindi6/docs/Hindi6.pdf_1_thumb.jpg",
          "/cu/Hindi/Hindi7/docs/Hindi7.pdf_1_thumb.jpg",
          "/cu/Hindi/Hindi8/docs/Hindi8.pdf_1_thumb.jpg",
          "/cu/Hindi/Hindi9/docs/Hindi9.pdf_1_thumb.jpg",
          "/cu/Hindi/Hindi10/docs/Hindi10.pdf_1_thumb.jpg",
      ],
      HyperLink:
        [
          "/cu/Hindi/Hindi1",
          "/cu/Hindi/Hindi2",
          "/cu/Hindi/Hindi3",
          "/cu/Hindi/Hindi4",
          "/cu/Hindi/Hindi5",
          "/cu/Hindi/Hindi6",
          "/cu/Hindi/Hindi7",
          "/cu/Hindi/Hindi8",
          "/cu/Hindi/Hindi9",
          "/cu/Hindi/Hindi10",

        ]
    },
    {
      Language: "Urdu", ImageUrl: [
        "/cu/Urdu/Urdu1/docs/Urdu1.pdf_1_thumb.jpg",
        "/cu/Urdu/Urdu2/docs/Urdu2.pdf_1_thumb.jpg",
        "/cu/Urdu/Urdu3/docs/Urdu3.pdf_1_thumb.jpg",
        "/cu/Urdu/Urdu4/docs/Urdu4.pdf_1_thumb.jpg",
        "/cu/Urdu/Urdu5/docs/Urdu5.pdf_1_thumb.jpg",
        "/cu/Urdu/Urdu6/docs/Urdu6.pdf_1_thumb.jpg",
        "/cu/Urdu/Urdu7/docs/Urdu7.pdf_1_thumb.jpg",
        "/cu/Urdu/Urdu8/docs/Urdu8.pdf_1_thumb.jpg",
        "/cu/Urdu/Urdu9/docs/Urdu9.pdf_1_thumb.jpg",
        "/cu/Urdu/Urdu10/docs/Urdu10.pdf_1_thumb.jpg",
      ],
      HyperLink:
        [
          "/cu/Urdu/Urdu1",
          "/cu/Urdu/Urdu2",
          "/cu/Urdu/Urdu3",
          "/cu/Urdu/Urdu4",
          "/cu/Urdu/Urdu5",
          "/cu/Urdu/Urdu6",
          "/cu/Urdu/Urdu7",
          "/cu/Urdu/Urdu8",
          "/cu/Urdu/Urdu9",
          "/cu/Urdu/Urdu10",

        ]
    },
    {
      Language: "Gujarati", ImageUrl: [
        "/cu/Gujarati/Gujarati1/docs/Gujarati1.pdf_1_thumb.jpg",
        "/cu/Gujarati/Gujarati2/docs/Gujarati2.pdf_1_thumb.jpg",
        "/cu/Gujarati/Gujarati3/docs/Gujarati3.pdf_1_thumb.jpg",
        "/cu/Gujarati/Gujarati4/docs/Gujarati4.pdf_1_thumb.jpg",
        "/cu/Gujarati/Gujarati5/docs/Gujarati5.pdf_1_thumb.jpg",
        "/cu/Gujarati/Gujarati6/docs/Gujarati6.pdf_1_thumb.jpg",
        "/cu/Gujarati/Gujarati7/docs/Gujarati7.pdf_1_thumb.jpg",
        "/cu/Gujarati/Gujarati8/docs/Gujarati8.pdf_1_thumb.jpg",
        "/cu/Gujarati/Gujarati9/docs/Gujarati9.pdf_1_thumb.jpg",
        "/cu/Gujarati/Gujarati10/docs/Gujarati10.pdf_1_thumb.jpg",
      ],
      HyperLink:
        [
          "/cu/Gujarati/Gujarati1",
          "/cu/Gujarati/Gujarati2",
          "/cu/Gujarati/Gujarati3",
          "/cu/Gujarati/Gujarati4",
          "/cu/Gujarati/Gujarati5",
          "/cu/Gujarati/Gujarati6",
          "/cu/Gujarati/Gujarati7",
          "/cu/Gujarati/Gujarati8",
          "/cu/Gujarati/Gujarati9",
          "/cu/Gujarati/Gujarati10",
        ]
    },
    {
      Language: "Bengali", ImageUrl:
        [
          "/cu/Bengali/Bengali1/docs/Bengali1.pdf_1_thumb.jpg",
          "/cu/Bengali/Bengali2/docs/Bengali2.pdf_1_thumb.jpg",
          "/cu/Bengali/Bengali3/docs/Bengali3.pdf_1_thumb.jpg",
          "/cu/Bengali/Bengali4/docs/Bengali4.pdf_1_thumb.jpg",
          "/cu/Bengali/Bengali5/docs/Bengali5.pdf_1_thumb.jpg",
          "/cu/Bengali/Bengali6/docs/Bengali6.pdf_1_thumb.jpg",
          "/cu/Bengali/Bengali7/docs/Bengali7.pdf_1_thumb.jpg",
          "/cu/Bengali/Bengali8/docs/Bengali8.pdf_1_thumb.jpg",
          "/cu/Bengali/Bengali9/docs/Bengali9.pdf_1_thumb.jpg",
          "/cu/Bengali/Bengali10/docs/Bengali10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Bengali/Bengali1",
          "/cu/Bengali/Bengali2",
          "/cu/Bengali/Bengali3",
          "/cu/Bengali/Bengali4",
          "/cu/Bengali/Bengali5",
          "/cu/Bengali/Bengali6",
          "/cu/Bengali/Bengali7",
          "/cu/Bengali/Bengali8",
          "/cu/Bengali/Bengali9",
          "/cu/Bengali/Bengali10",

        ]
    },
    {
      Language: "Kannada", ImageUrl: [
        "/cu/Kannada/Kannada1/docs/Kannada1.pdf_1_thumb.jpg",
        "/cu/Kannada/Kannada2/docs/Kannada2.pdf_1_thumb.jpg",
        "/cu/Kannada/Kannada3/docs/Kannada3.pdf_1_thumb.jpg",
        "/cu/Kannada/Kannada4/docs/Kannada4.pdf_1_thumb.jpg",
        "/cu/Kannada/Kannada5/docs/Kannada5.pdf_1_thumb.jpg",
        "/cu/Kannada/Kannada6/docs/Kannada6.pdf_1_thumb.jpg",
        "/cu/Kannada/Kannada7/docs/Kannada7.pdf_1_thumb.jpg",
        "/cu/Kannada/Kannada8/docs/Kannada8.pdf_1_thumb.jpg",
        "/cu/Kannada/Kannada9/docs/Kannada9.pdf_1_thumb.jpg",
        "/cu/Kannada/Kannada10/docs/Kannada10.pdf_1_thumb.jpg",
      ],
      HyperLink:
        [
          "/cu/Kannada/Kannada1",
          "/cu/Kannada/Kannada2",
          "/cu/Kannada/Kannada3",
          "/cu/Kannada/Kannada4",
          "/cu/Kannada/Kannada5",
          "/cu/Kannada/Kannada6",
          "/cu/Kannada/Kannada7",
          "/cu/Kannada/Kannada8",
          "/cu/Kannada/Kannada9",
          "/cu/Kannada/Kannada10",
        ]
    },
    {
      Language: "Maithili", ImageUrl:
        [
          "/cu/Maithili/Maithili1/docs/Maithili1.pdf_1_thumb.jpg",
          "/cu/Maithili/Maithili2/docs/Maithili2.pdf_13_thumb.jpg",
          "/cu/Maithili/Maithili3/docs/Maithili3.pdf_1_thumb.jpg",
          "/cu/Maithili/Maithili4/docs/Maithili4.pdf_1_thumb.jpg",
          "/cu/Maithili/Maithili5/docs/Maithili5.pdf_1_thumb.jpg",
          "/cu/Maithili/Maithili6/docs/Maithili6.pdf_1_thumb.jpg",
          "/cu/Maithili/Maithili7/docs/Maithili7.pdf_1_thumb.jpg",
          "/cu/Maithili/Maithili8/docs/Maithili8.pdf_1_thumb.jpg",
          "/cu/Maithili/Maithili9/docs/Maithili9.pdf_1_thumb.jpg",
          "/cu/Maithili/Maithili10/docs/Maithili10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Maithili/Maithili1",
          "/cu/Maithili/Maithili2",
          "/cu/Maithili/Maithili3",
          "/cu/Maithili/Maithili4",
          "/cu/Maithili/Maithili5",
          "/cu/Maithili/Maithili6",
          "/cu/Maithili/Maithili7",
          "/cu/Maithili/Maithili8",
          "/cu/Maithili/Maithili9",
          "/cu/Maithili/Maithili10",
        ],
    },
    {
      Language: "Telugu", ImageUrl:
        [
          "/cu/Telugu/Telugu1/docs/Telugu1.pdf_1_thumb.jpg",
          "/cu/Telugu/Telugu2/docs/Telugu2.pdf_1_thumb.jpg",
          "/cu/Telugu/Telugu3/docs/Telugu3.pdf_1_thumb.jpg",
          "/cu/Telugu/Telugu4/docs/Telugu4.pdf_1_thumb.jpg",
          "/cu/Telugu/Telugu5/docs/Telugu5.pdf_1_thumb.jpg",
          "/cu/Telugu/Telugu6/docs/Telugu6.pdf_1_thumb.jpg",
          "/cu/Telugu/Telugu7/docs/Telugu7.pdf_1_thumb.jpg",
          "/cu/Telugu/Telugu8/docs/Telugu8.pdf_1_thumb.jpg",
          "/cu/Telugu/Telugu9/docs/Telugu9.pdf_1_thumb.jpg",
          "/cu/Telugu/Telugu10/docs/Telugu10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Telugu/Telugu1",
          "/cu/Telugu/Telugu2",
          "/cu/Telugu/Telugu3",
          "/cu/Telugu/Telugu4",
          "/cu/Telugu/Telugu5",
          "/cu/Telugu/Telugu6",
          "/cu/Telugu/Telugu7",
          "/cu/Telugu/Telugu8",
          "/cu/Telugu/Telugu9",
          "/cu/Telugu/Telugu10",
        ],
    },
    {
      Language: "Nepali", ImageUrl:
        [
          "/cu/Nepali/Nepali1/docs/Nepali1.pdf_1_thumb.jpg",
          "/cu/Nepali/Nepali2/docs/Nepali2.pdf_1_thumb.jpg",
          "/cu/Nepali/Nepali3/docs/Nepali3.pdf_1_thumb.jpg",
          "/cu/Nepali/Nepali4/docs/Nepali4.pdf_1_thumb.jpg",
          "/cu/Nepali/Nepali5/docs/Nepali5.pdf_1_thumb.jpg",
          "/cu/Nepali/Nepali6/docs/Nepali6.pdf_1_thumb.jpg",
          "/cu/Nepali/Nepali7/docs/Nepali7.pdf_1_thumb.jpg",
          "/cu/Nepali/Nepali8/docs/Nepali8.pdf_1_thumb.jpg",
          "/cu/Nepali/Nepali9/docs/Nepali9.pdf_1_thumb.jpg",
          "/cu/Nepali/Nepali10/docs/Nepali10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Nepali/Nepali1",
          "/cu/Nepali/Nepali2",
          "/cu/Nepali/Nepali3",
          "/cu/Nepali/Nepali4",
          "/cu/Nepali/Nepali5",
          "/cu/Nepali/Nepali6",
          "/cu/Nepali/Nepali7",
          "/cu/Nepali/Nepali8",
          "/cu/Nepali/Nepali9",
          "/cu/Nepali/Nepali10",
        ],
    },
    {
      Language: "Tamil", ImageUrl:
        [
          "/cu/Tamil/Tamil1/docs/Tamil1.pdf_1_thumb.jpg",
          "/cu/Tamil/Tamil2/docs/Tamil2.pdf_1_thumb.jpg",
          "/cu/Tamil/Tamil3/docs/Tamil3.pdf_1_thumb.jpg",
          "/cu/Tamil/Tamil4/docs/Tamil4.pdf_1_thumb.jpg",
          "/cu/Tamil/Tamil5/docs/Tamil5.pdf_1_thumb.jpg",
          "/cu/Tamil/Tamil6/docs/Tamil6.pdf_1_thumb.jpg",
          "/cu/Tamil/Tamil7/docs/Tamil7.pdf_1_thumb.jpg",
          "/cu/Tamil/Tamil8/docs/Tamil8.pdf_1_thumb.jpg",
          "/cu/Tamil/Tamil9/docs/Tamil9.pdf_1_thumb.jpg",
          "/cu/Tamil/Tamil10/docs/Tamil10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Tamil/Tamil1",
          "/cu/Tamil/Tamil2",
          "/cu/Tamil/Tamil3",
          "/cu/Tamil/Tamil4",
          "/cu/Tamil/Tamil5",
          "/cu/Tamil/Tamil6",
          "/cu/Tamil/Tamil7",
          "/cu/Tamil/Tamil8",
          "/cu/Tamil/Tamil9",
          "/cu/Tamil/Tamil10"
        ],
    },
    {
      Language: "Sanskrit", ImageUrl:
        [
         "/cu/Sanskrit/Sanskrit1/docs/Sanskrit1.pdf_1_thumb.jpg",
          "/cu/Sanskrit/Sanskrit2/docs/Sanskrit2.pdf_1_thumb.jpg",
          "/cu/Sanskrit/Sanskrit3/docs/Sanskrit3.pdf_1_thumb.jpg",
          "/cu/Sanskrit/Sanskrit4/docs/Sanskrit4.pdf_1_thumb.jpg",
          "/cu/Sanskrit/Sanskrit5/docs/Sanskrit5.pdf_1_thumb.jpg",
          "/cu/Sanskrit/Sanskrit6/docs/Sanskrit6.pdf_1_thumb.jpg",
          "/cu/Sanskrit/Sanskrit7/docs/Sanskrit7.pdf_1_thumb.jpg",
          "/cu/Sanskrit/Sanskrit8/docs/Sanskrit8.pdf_1_thumb.jpg",
          "/cu/Sanskrit/Sanskrit9/docs/Sanskrit9.pdf_1_thumb.jpg",
          "/cu/Sanskrit/Sanskrit10/docs/Sanskrit10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Sanskrit/Sanskrit1",
          "/cu/Sanskrit/Sanskrit2",
          "/cu/Sanskrit/Sanskrit3",
          "/cu/Sanskrit/Sanskrit4",
          "/cu/Sanskrit/Sanskrit5",
          "/cu/Sanskrit/Sanskrit6",
          "/cu/Sanskrit/Sanskrit7",
          "/cu/Sanskrit/Sanskrit8",
          "/cu/Sanskrit/Sanskrit9",
          "/cu/Sanskrit/Sanskrit10",
        ]
    },
    {
      Language: "Odia", ImageUrl:
        [
          "/cu/Odia/Odia1/docs/Odia1.pdf_1_thumb.jpg",
          "/cu/Odia/Odia2/docs/Odia2.pdf_1_thumb.jpg",
          "/cu/Odia/Odia3/docs/Odia3.pdf_1_thumb.jpg",
          "/cu/Odia/Odia4/docs/Odia4.pdf_1_thumb.jpg",
          "/cu/Odia/Odia5/docs/Odia5.pdf_1_thumb.jpg",
          "/cu/Odia/Odia6/docs/Odia6.pdf_1_thumb.jpg",
          "/cu/Odia/Odia7/docs/Odia7.pdf_1_thumb.jpg",
          "/cu/Odia/Odia8/docs/Odia8.pdf_1_thumb.jpg",
          "/cu/Odia/Odia9/docs/Odia9.pdf_1_thumb.jpg",
          "/cu/Odia/Odia10/docs/Odia10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Odia/Odia1",
          "/cu/Odia/Odia2",
          "/cu/Odia/Odia3",
          "/cu/Odia/Odia4",
          "/cu/Odia/Odia5",
          "/cu/Odia/Odia6",
          "/cu/Odia/Odia7",
          "/cu/Odia/Odia8",
          "/cu/Odia/Odia9",
          "/cu/Odia/Odia10",
        ]
    },
    {
      Language: "Marathi", ImageUrl:
        [
          "/cu/Marathi/1-1/docs/1-1.pdf_1_thumb.jpg",
          "/cu/Marathi/1-2/docs/1-2.pdf_1_thumb.jpg",
          "/cu/Marathi/1-3/docs/1-3.pdf_1_thumb.jpg",
          "/cu/Marathi/1-4/docs/1-4.pdf_1_thumb.jpg",
          "/cu/Marathi/1-5/docs/1-5.pdf_1_thumb.jpg",
          "/cu/Marathi/1-6/docs/1-6.pdf_1_thumb.jpg",
          "/cu/Marathi/1-7/docs/1-7.pdf_1_thumb.jpg",
          "/cu/Marathi/1-8/docs/1-8.pdf_1_thumb.jpg",
          "/cu/Marathi/1-9/docs/1-9.pdf_1_thumb.jpg",
          "/cu/Marathi/1-10/docs/1-10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Marathi/1-1",
          "/cu/Marathi/1-2",
          "/cu/Marathi/1-3",
          "/cu/Marathi/1-4",
          "/cu/Marathi/1-5",
          "/cu/Marathi/1-6",
          "/cu/Marathi/1-7",
          "/cu/Marathi/1-8",
          "/cu/Marathi/1-9",
          "/cu/Marathi/1-10",
        ]
    },
    {
      Language: "Sindhi", ImageUrl:
        [
         "/cu/Sindhi/Sindhi_1/docs/Sindhi_1.pdf_1_thumb.jpg",
          "/cu/Sindhi/Sindhi_2/docs/Sindhi_2.pdf_1_thumb.jpg",
          "/cu/Sindhi/Sindhi_3/docs/Sindhi_3.pdf_1_thumb.jpg",
          "/cu/Sindhi/Sindhi_4/docs/Sindhi_4.pdf_1_thumb.jpg",
          "/cu/Sindhi/Sindhi_5/docs/Sindhi_5.pdf_1_thumb.jpg",
          "/cu/Sindhi/Sindhi_6/docs/Sindhi_6.pdf_1_thumb.jpg",
          "/cu/Sindhi/Sindhi_7/docs/Sindhi_7.pdf_1_thumb.jpg",
          "/cu/Sindhi/Sindhi_8/docs/Sindhi_8.pdf_1_thumb.jpg",
          "/cu/Sindhi/Sindhi_9/docs/Sindhi_9.pdf_1_thumb.jpg",
          "/cu/Sindhi/Sindhi_10/docs/Sindhi_10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Sindhi/Sindhi_1",
          "/cu/Sindhi/Sindhi_2",
          "/cu/Sindhi/Sindhi_3",
          "/cu/Sindhi/Sindhi_4",
          "/cu/Sindhi/Sindhi_5",
          "/cu/Sindhi/Sindhi_6",
          "/cu/Sindhi/Sindhi_7",
          "/cu/Sindhi/Sindhi_8",
          "/cu/Sindhi/Sindhi_9",
          "/cu/Sindhi/Sindhi_10",
        ],
    },
    {
      Language: "Manipuri", ImageUrl:
        [
          "/cu/Manipuri/Manipuri-1-3/docs/Manipuri-1-3.pdf_1_thumb.jpg",
          "/cu/Manipuri/Manipuri-1-4/docs/Manipuri-1-4.pdf_1_thumb.jpg",
          "/cu/Manipuri/Manipuri-1-5/docs/Manipuri-1-5.pdf_1_thumb.jpg",
          "/cu/Manipuri/Manipuri-1-6/docs/Manipuri-1-6.pdf_1_thumb.jpg",
          "/cu/Manipuri/Manipuri-1-10/docs/Manipuri-1-10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [

          "/cu/Manipuri/Manipuri-1-3",
          "/cu/Manipuri/Manipuri-1-4",
          "/cu/Manipuri/Manipuri-1-5",
          "/cu/Manipuri/Manipuri-1-6",
          "/cu/Manipuri/Manipuri-1-10"
        ],
    },
    {
      Language: "Assamese", ImageUrl:
        [
         "/cu/Assam/Assam-1-1/docs/Assam-1-1.pdf_1_thumb.jpg",
          "/cu/Assam/Assam-1-2/docs/Assam-1-2.pdf_1_thumb.jpg",
          "/cu/Assam/Assam-1-3/docs/Assam-1-3.pdf_1_thumb.jpg",
          "/cu/Assam/Assam-1-4/docs/Assam-1-4.pdf_1_thumb.jpg",
          "/cu/Assam/Assam-1-5/docs/Assam-1-5.pdf_1_thumb.jpg",
          "/cu/Assam/Assam-1-6/docs/Assam-1-6.pdf_1_thumb.jpg",
          "/cu/Assam/Assam-1-7/docs/Assam-1-7.pdf_1_thumb.jpg",
          "/cu/Assam/Assam-1-8/docs/Assam-1-8.pdf_1_thumb.jpg",
          "/cu/Assam/Assam-1-9/docs/Assam-1-9.pdf_1_thumb.jpg",
          "/cu/Assam/Assam-1-10/docs/Assam-1-10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Assam/Assam-1-1",
          "/cu/Assam/Assam-1-2",
          "/cu/Assam/Assam-1-3",
          "/cu/Assam/Assam-1-4",
          "/cu/Assam/Assam-1-5",
          "/cu/Assam/Assam-1-6",
          "/cu/Assam/Assam-1-7",
          "/cu/Assam/Assam-1-8",
          "/cu/Assam/Assam-1-9",
          "/cu/Assam/Assam-1-10",

        ],
    },
    {
      Language: "Malayalam", ImageUrl:
        [
          "/cu/Malayalam/Malayalam1-1/docs/Malayalam1-1.pdf_1_thumb.jpg",
          "/cu/Malayalam/Malayalam1-2/docs/Malayalam1-2.pdf_1_thumb.jpg",
          "/cu/Malayalam/Malayalam1-3/docs/Malayalam1-3.pdf_1_thumb.jpg",
          "/cu/Malayalam/Malayalam1-4/docs/Malayalam1-4.pdf_1_thumb.jpg",
          "/cu/Malayalam/Malayalam1-5/docs/Malayalam1-5.pdf_1_thumb.jpg",
          "/cu/Malayalam/Malayalam1-6/docs/Malayalam1-6.pdf_1_thumb.jpg",
          "/cu/Malayalam/Malayalam1-7/docs/Malayalam1-7.pdf_1_thumb.jpg",
          "/cu/Malayalam/Malayalam1-8/docs/Malayalam1-8.pdf_1_thumb.jpg",
          "/cu/Malayalam/Malayalam1-9/docs/Malayalam1-9.pdf_1_thumb.jpg",
          "/cu/Malayalam/Malayalam1-10/docs/Malayalam1-10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Malayalam/Malayalam1-1",
          "/cu/Malayalam/Malayalam1-2",
          "/cu/Malayalam/Malayalam1-3",
          "/cu/Malayalam/Malayalam1-4",
          "/cu/Malayalam/Malayalam1-5",
          "/cu/Malayalam/Malayalam1-6",
          "/cu/Malayalam/Malayalam1-7",
          "/cu/Malayalam/Malayalam1-8",
          "/cu/Malayalam/Malayalam1-9",
          "/cu/Malayalam/Malayalam1-10",
        ],
    },
    {
      Language: "Punjabi", ImageUrl:
        [
         "/cu/Punjabi/Punjabi-1-1/docs/Punjabi-1-1.pdf_1_thumb.jpg",
          "/cu/Punjabi/Punjabi-1-2/docs/Punjabi-1-2.pdf_1_thumb.jpg",
          "/cu/Punjabi/Punjabi-1-3/docs/Punjabi-1-3.pdf_1_thumb.jpg",
          "/cu/Punjabi/Punjabi-1-4/docs/Punjabi-1-4.pdf_1_thumb.jpg",
          "/cu/Punjabi/Punjabi-1-5/docs/Punjabi-1-5.pdf_1_thumb.jpg",
          "/cu/Punjabi/Punjabi-1-6/docs/Punjabi-1-6.pdf_1_thumb.jpg",
          "/cu/Punjabi/Punjabi-1-7/docs/Punjabi-1-7.pdf_1_thumb.jpg",
          "/cu/Punjabi/Punjabi-1-8/docs/Punjabi-1-8.pdf_1_thumb.jpg",
          "/cu/Punjabi/Punjabi-1-9/docs/Punjabi-1-9.pdf_1_thumb.jpg",
          "/cu/Punjabi/Punjabi-1-10/docs/Punjabi-1-10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Punjabi/Punjabi-1-1",
          "/cu/Punjabi/Punjabi-1-2",
          "/cu/Punjabi/Punjabi-1-3",
          "/cu/Punjabi/Punjabi-1-4",
          "/cu/Punjabi/Punjabi-1-5",
          "/cu/Punjabi/Punjabi-1-6",
          "/cu/Punjabi/Punjabi-1-7",
          "/cu/Punjabi/Punjabi-1-8",
          "/cu/Punjabi/Punjabi-1-9",
          "/cu/Punjabi/Punjabi-1-10",
        ],
    },
    {
      Language: "Kashmiri", ImageUrl:
        [
          "/cu/Kashmiri/Kashmiri-1-1/docs/Kashmiri-1-1.pdf_1_thumb.jpg",
          "/cu/Kashmiri/Kashmiri-1-2/docs/Kashmiri-1-2.pdf_1_thumb.jpg",
          "/cu/Kashmiri/Kashmiri-1-3/docs/Kashmiri-1-3.pdf_1_thumb.jpg",
          "/cu/Kashmiri/Kashmiri-1-4/docs/Kashmiri-1-4.pdf_1_thumb.jpg",
          "/cu/Kashmiri/Kashmiri-1-5/docs/Kashmiri-1-5.pdf_1_thumb.jpg",
          "/cu/Kashmiri/Kashmiri-1-6/docs/Kashmiri-1-6.pdf_1_thumb.jpg",
          "/cu/Kashmiri/Kashmiri-1-7/docs/Kashmiri-1-7.pdf_1_thumb.jpg",
          "/cu/Kashmiri/Kashmiri-1-8/docs/Kashmiri-1-8.pdf_1_thumb.jpg",
          "/cu/Kashmiri/Kashmiri-1-9/docs/Kashmiri-1-9.pdf_1_thumb.jpg",
          "/cu/Kashmiri/Kashmiri-1-10/docs/Kashmiri-1-10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Kashmiri/Kashmiri-1-1",
          "/cu/Kashmiri/Kashmiri-1-2",
          "/cu/Kashmiri/Kashmiri-1-3",
          "/cu/Kashmiri/Kashmiri-1-4",
          "/cu/Kashmiri/Kashmiri-1-5",
          "/cu/Kashmiri/Kashmiri-1-6",
          "/cu/Kashmiri/Kashmiri-1-7",
          "/cu/Kashmiri/Kashmiri-1-8",
          "/cu/Kashmiri/Kashmiri-1-9",
          "/cu/Kashmiri/Kashmiri-1-10",
        ],
    },
    {
      Language: "Dogri", ImageUrl:
        [
         "/cu/Dogri/Dogri-1-1a/docs/Dogri-1-1a.pdf_1_thumb.jpg",
          "/cu/Dogri/Dogri-1-2a/docs/Dogri-1-2a.pdf_1_thumb.jpg",
          "/cu/Dogri/Dogri-1-3a/docs/Dogri-1-3a.pdf_1_thumb.jpg",
          "/cu/Dogri/Dogri-1-4/docs/Dogri-1-4.pdf_1_thumb.jpg",
          "/cu/Dogri/Dogri-1-5a/docs/Dogri-1-5a.pdf_1_thumb.jpg",
          "/cu/Dogri/Dogri-1-6a/docs/Dogri-1-6a.pdf_1_thumb.jpg",
          "/cu/Dogri/Dogri-1-7a/docs/Dogri-1-7a.pdf_1_thumb.jpg",
          "/cu/Dogri/Dogri-1-8a/docs/Dogri-1-8a.pdf_1_thumb.jpg",
          "/cu/Dogri/Dogri-1-9a/docs/Dogri-1-9a.pdf_1_thumb.jpg",
          "/cu/Dogri/Dogri-1-10/docs/Dogri-1-10.pdf_1_thumb.jpg",
        ],
      HyperLink:
        [
          "/cu/Dogri/Dogri-1-1a",
          "/cu/Dogri/Dogri-1-2a",
          "/cu/Dogri/Dogri-1-3a",
          "/cu/Dogri/Dogri-1-4",
          "/cu/Dogri/Dogri-1-5a",
          "/cu/Dogri/Dogri-1-6a",
          "/cu/Dogri/Dogri-1-7a",
          "/cu/Dogri/Dogri-1-8a",
          "/cu/Dogri/Dogri-1-9a",
          "/cu/Dogri/Dogri-1-10",


        ],
    }
  ],
  "G20": [
    {
      Language: "English",
      themeImage:modG20Logo,
      ImageUrl: [
        "/g20/G20F1E/docs/G20F1E.pdf_1_thumb.jpg",
          "/g20/G20P2E/docs/G20P2E.pdf_1_thumb.jpg",
          "/g20/G20M3E/docs/G20M3E.pdf_1_thumb.jpg",
          "/g20/G20M4E/docs/G20M4E.pdf_1_thumb.jpg",
          "/g20/G20M5E/docs/G20M5E.pdf_1_thumb.jpg",
          "/g20/G20S6E/docs/G20S6E.pdf_1_thumb.jpg",
          "/g20/G20HS7E/docs/G20HS7E.pdf_1_thumb.jpg",
          "/g20/G20HS8E/docs/G20HS8E.pdf_1_thumb.jpg",

      ],
      HyperLink:
        [
          "/g20/G20F1E",
          "/g20/G20P2E",
          "/g20/G20M3E",
          "/g20/G20M4E",
          "/g20/G20M5E",
          "/g20/G20S6E",
          "/g20/G20HS7E",
          "/g20/G20HS8E",
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_English_G201FE.pdf",
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_English_G202PE.pdf",
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_English_G203ME.pdf",
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_English_G204ME.pdf",
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_English_G205ME.pdf",
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_English_G206SE.pdf",
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_English_G207HSE.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_English_G208HSE.pdf",

        ],
    },
    {
      Language: "Hindi", ImageUrl: [
       "/g20/G20F1H/docs/G20F1H.pdf_1_thumb.jpg",
          "/g20/G20P2H/docs/G20P2H.pdf_1_thumb.jpg",
          "/g20/G20M3H/docs/G20M3H.pdf_1_thumb.jpg",
          "/g20/G20M4H/docs/G20M4H.pdf_1_thumb.jpg",
          "/g20/G20M5H/docs/G20M5H.pdf_1_thumb.jpg",
          "/g20/G20S6H/docs/G20S6H.pdf_1_thumb.jpg",
          "/g20/G20HS7H/docs/G20HS7H.pdf_1_thumb.jpg",
          "/g20/G20HS8H/docs/G20HS8H.pdf_1_thumb.jpg",

      ],
      HyperLink:
        [
          "/g20/G20F1H",
          "/g20/G20P2H",
          "/g20/G20M3H",
          "/g20/G20M4H",
          "/g20/G20M5H",
          "/g20/G20S6H",
          "/g20/G20HS7H",
          "/g20/G20HS8H",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_hindi_G201FH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_hindi_G202PH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_hindi_G203MH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_hindi_G204MH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_hindi_G205MH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_hindi_G206SH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_hindi_G207HSH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/g20_hindi_G208HSH.pdf"
        ],
    }
  ],
  "Nari Shakti": [
    {
      Language: "English",
      themeImage: modNSLogo,
      ImageUrl: [
        "/ns/NSF1E/docs/NSF1E.pdf_1_thumb.jpg",
        "/ns/NSF2E/docs/NSF2E.pdf_1_thumb.jpg",
        "/ns/NSS3E/docs/NSS3E.pdf_1_thumb.jpg",
        "/ns/NSHS4E/docs/NSHS4E.pdf_1_thumb.jpg",
        "/ns/NSS5E/docs/NSS5E.pdf_1_thumb.jpg",
        "/ns/NSM6E/docs/NSM6E.pdf_1_thumb.jpg",
        "/ns/NSS-HS7E/docs/NSS-HS7E.pdf_1_thumb.jpg",

      ],
      HyperLink:
        [
          "/ns/NSF1E",
          "/ns/NSF2E",
          "/ns/NSS3E",
          "/ns/NSHS4E",
          "/ns/NSS5E",
          "/ns/NSM6E",
          "/ns/NSS-HS7E",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_English_NS1FE.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_English_NS2FE.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_English_NS3SE.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_English_NS4HSE.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_English_NS5SE.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_English_NS6ME.pdf",

        ],
    },
    {
      Language: "Hindi", ImageUrl: [
        "/ns/NSF1H/docs/NSF1H.pdf_1_thumb.jpg",
          "/ns/NSF2H/docs/NSF2H.pdf_1_thumb.jpg",
          "/ns/NSS3H/docs/NSS3H.pdf_1_thumb.jpg",
          "/ns/NSHS4H/docs/NSHS4H.pdf_1_thumb.jpg",
          "/ns/NSS5H/docs/NSS5H.pdf_1_thumb.jpg",
          "/ns/NSM6H/docs/NSM6H.pdf_1_thumb.jpg",
          "/ns/NSS-HS7H/docs/NSS-HS7H.pdf_1_thumb.jpg",
      ],
      HyperLink:
        [
          "/ns/NSF1H",
          "/ns/NSF2H",
          "/ns/NSS3H",
          "/ns/NSHS4H",
          "/ns/NSS5H",
          "/ns/NSM6H",
          "/ns/NSS-HS7H",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_Hindi_NS1FH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_Hindi_NS2FH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_Hindi_NS3SH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_Hindi_NS4HSH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_Hindi_NS5SH.pdf",
          // "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/ns_Hindi_NS6MH.pdf",
        ]
    }
  ],
  "Vikshit Bharat": [
    {
      Language: "Hindi",
      themeImage:modVBLogo,
      ImageUrl: [
        '/vs/4_1F/docs/4_1F.pdf_1_thumb.jpg',
        '/vs/4_2P/docs/4_2P.pdf_1_thumb.jpg',
        '/vs/4_3M/docs/4_3M.pdf_1_thumb.jpg',
        '/vs/4_4S/docs/4_4S.pdf_1_thumb.jpg',
        '/vs/4_5HSK/docs/4_5HSK.pdf_1_thumb.jpg',

      ],
      HyperLink:
      [
        '/vs/4_1F',
        '/vs/4_2P',
        '/vs/4_3M',
        '/vs/4_4S',
        '/vs/4_5HSK'
      ]
        // [
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/vb_Hindi_4_1F.pdf",
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/vb_Hindi_4_2P.pdf",
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/vb_Hindi_4_3M.pdf",
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/vb_Hindi_4_4S.pdf",
        //   "https://objectstorage.ap-mumbai-1.oraclecloud.com/p/uEMzZ7cXv1DFROWTsXSdB7Zge4r1WNY5QajBIdLzjm-RsLWK2fj1DpyS2vk7zgRT/n/bmzbbujw9kal/b/NCERTQUIZ-PROD-Bucket/o/vb_Hindi_4_5HSK.pdf"
        // ]
    }
  ]

};

