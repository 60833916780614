import * as React from "react";
import {useNavigate} from 'react-router-dom';
import back from '../resources/assets/backs.png';
  
export default function BackButton(){
    const navigate = useNavigate();
    const [userData, setUserData] = React.useState([]);

          React.useEffect(async () => {
    
        if (userData.length === 0) {await getUserInformation()};    
      
    });

    const getUserInformation = async () => {
      const userID = localStorage.getItem("userID");
      // //console.log(JSON.parse(userID));
      //console.log(typeof(JSON.parse(userID)));
      if(userID===null)
      {

      }
      else{
          //console.log("hello");
          setUserData(JSON.parse(userID));    
      }
      
  }
  return(
<div style={{margin: '2% auto 0'}}>
<button className="actbtn" onClick={() => navigate(-1)}>
{userData.lang_id===2?<>
  गतिविधि बोर्ड
     </>
                      :
                      <>Activity Board</>}
      <span className="actbtnicon">»</span>
    </button>
    </div>
  )
}