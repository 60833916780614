import React,{useState, useEffect} from "react";
import './Grade.css';


function Grade() {
    const [userData, setUserData] = useState([]);

    useEffect(async () => {
    
        if (userData.length === 0) {await getUserInformation()};    
      
    });
    const getUserInformation = async () => {
        const userID = localStorage.getItem("userID");
        // console.log(JSON.parse(userID));
        // console.log(typeof(JSON.parse(userID)));
        if(userID===null)
        {

        }
        else{
            // console.log("hello");
            setUserData(JSON.parse(userID));    
        }
        
    }
    return (  
       <> 
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {/* <p style={{margin: '0 20', fontWeight: 500, textTransform: 'none'}}> {userData.user_firstname}   |   {userData.grade===1 ? 'Preparatory: 3-5' : userData.grade===2 ? 'Middle: 6-8' : userData.grade===3 ? 'Secondary: 9-12' : 'Other'}   |   Language</p> */}
            <p className="headerpara" style={{ }}> {userData.user_firstname}   |   {userData.grade===1 ? 'Preparatory: 3-5' : userData.grade===2 ? 'Middle: 6-8' : userData.grade===3 ? (userData.userGradeType!='Student'? userData.userGradeType : 'Secondary: 9-12') : 'Other'}   |  {userData.lang_id===1 ? 'English' : userData.lang_id===2 ? 'Hindi' :userData.lang_id===7 ? 'Urdu' : 'Language'}</p>
    
            {/* <span style={{padding: 3,fontSize: "0.75vw",fontWeight: "500" ,width: "40%", background: "rgb(87 51 41)", borderRadius: "9px", color: "rgb(255 255 255)", display: "flex",justifyContent: "center", alignItems: "center", textAlign: "center", boxShadow: "-2px 0px 4px rgb(0 0 0 / 20%)"}}>
                {/* {this.props.gameAttr.gameData[this.props.gameAttr.id].lang.lang_desc} 
                English
                </span> */}
            {/* <p style={{margin: 0, fontWeight: 500}}><span>Student Grade: </span></p> */}
        </div>
        </>
        
    );
}

export default Grade;