import React from 'react';
import { Document, Page } from 'react-pdf';

import HTMLFlipBook from "react-pageflip";

import Container from 'react-bootstrap/Container';



import './Flipbooks.css';

const Flipbooks = ({flipBookUrl}) => {
  const [numPages, setNumPages] = React.useState();
  const [pageNumber, setPageNumber] = React.useState(1);
  console.log(flipBookUrl);
  
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };


  return (
    <>


<Container>

    <div style={{width: '100%',}}>
      <Document  file={flipBookUrl} onLoadSuccess={onDocumentLoadSuccess} renderMode='canvas' >
      <HTMLFlipBook width={636} height={826} 
      // showCover={true}
 style={{margin: '1% auto', overflow: 'hidden'}}>
        {Array.apply(null, Array(numPages)).map((x, i)=> i+1 ).map((page)=>{
          return(
            <div>
              <Page pageNumber={page} renderAnnotationLayer={false} renderTextLayer={false} />
            </div>
          )
        })}
      </HTMLFlipBook>  
      </Document>
      {/* <div style={{position: 'relative', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center',}} className="sprevnextBtns">
      <button className="certificateButton" style={{padding: '1% 2%'}}>Prev</button>
      <button className="certificateButton" style={{}}>Next</button>
      </div> */}
      </div>
      <a
        href={flipBookUrl}
        download="PDF"
        target="_blank"
        rel="noreferrer"
      >
      <button className="certificateButton" style={{}}>Download</button>
      </a>
      </Container>

      {/* <div className="demoPage">Page 1</div>
      <div className="demoPage">Page 2</div>
      <div className="demoPage">Page 3</div>
      <div className="demoPage">Page 4</div> */}
    

    {/* <div class="book">
        <input type="checkbox" id="c1" />
        <input type="checkbox" id="c2" />
        <input type="checkbox" id="c3" />
        <div id="cover">
            <img src="https://images.pexels.com/photos/7214434/pexels-photo-7214434.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="" />
        </div>
        <div class="flip-book">
            <div class="flip" id="p1">
                <div class="back">
                    <img src="https://images.pexels.com/photos/1114797/pexels-photo-1114797.jpeg?cs=srgb&dl=pexels-angele-j-1114797.jpg&fm=jpg" alt="" />
                    <label  for="c1" class="back-btn">Before</label>
                </div>
                <div class="front">
                    <h2>Apple</h2>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus sunt consectetur iusto sint architecto beatae fuga, quasi, repellendus tempora cum eaque? Incidunt modi laudantium fugit nostrum ipsam consectetur. Illum, dolorum!</p>
                    <label for="c1" class="next-btn">NEXT</label>
                </div>
            </div>

            <div class="flip" id="p2">
                <div class="back">
                    <img src="https://images.pexels.com/photos/1998630/pexels-photo-1998630.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="" />
                    <label for="c2" class="back-btn">Before</label>
                </div>
                <div class="front">
                    <h2>Orange</h2>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus sunt consectetur iusto sint architecto beatae fuga, quasi, repellendus tempora cum eaque? Incidunt modi laudantium fugit nostrum ipsam consectetur. Illum, dolorum!</p>
                    <label for="c2" class="next-btn">NEXT</label>
                </div>
            </div>
            
            <div class="flip" id="p3">
                <div class="back">
                    <label for="c3" class="back-btn">Before</label>
                </div>
                <div class="front">
                    <h2>strawberry</h2>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus sunt consectetur iusto sint architecto beatae fuga, quasi, repellendus tempora cum eaque? Incidunt modi laudantium fugit nostrum ipsam consectetur. Illum, dolorum!</p>
                    <label for="c3" class="next-btn">NEXT</label>
                </div>
            </div>

        </div>
    </div> */}
    </>
  );
};

export default Flipbooks;