import React, { useState } from "react";
import EventDataParser from "../../Services/EventDataParser";
import PicturePuzzle from "./PicturePuzzle";
  
  function ImplementPicturePuzzle() {
    const [gameData, setGameData] =useState([]);
    const [eventData, setEventData] =useState([]);
    const gradeData = {"pre":1, "mid": 2, "sec": 3}
    const [gameAttr, setGameAttr] =useState({});
    const [day, setDay] =useState('');

    React.useEffect(() => {
      if(gameData.length <=0)
        getTileData();
    },[]);


      async function getTileData() {
        let data = await EventDataParser();
        setGameData(data);
      if(data.length > 0)
      {
        
        data.forEach(element => {
          let newGameData = element[0];
          // if(newGameData.tile_grade_id === gradeData[grade]){
            if(newGameData.tile_grade_id === gradeData['mid']){
              setEventData(newGameData);
             if(newGameData.event_type_id==2)
              {
                let gameData = newGameData.tile_game_info[0].multiGameData
                setGameData(gameData);
                let getDate=newGameData.tile_game_info[0].forDate;
                let day = getDate
                setDay(day);
//////////////////////////////////////////////////////////////////////////// 
                let game = newGameData.tile_game_info[0].multiGameData[0].game;
                game.forEach(element => {
                  if(element.game_type_id === 6)
                  {
                    let gameAttr = element;
                // console.log(typeof(gameAttr))
                    setGameAttr(element)
                  }
                });
                
              }
          }
        });
      }
      // save to database name and grade
    }

    return (
        <>
        {
          (gameData.length > 0 && Object.keys(eventData).length >0 && Object.keys(gameAttr).length >0 )&&
          <PicturePuzzle
          gameAttr={gameAttr}
          gameData={gameData}
          eventData={eventData}
          day={day}
          />
        }
        
        </>
      );
    }
  
     export default ImplementPicturePuzzle;