// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InspiringStories_rackWiseModule__Nlwjv {\r\n    width: 85% !important;\r\n    margin: 0 auto !important;\r\n    justify-content: center;\r\n    /* background-image: url('../resources/assets/EXPORTS/NOVEL/PNG/Asset 13@4x.png'); */\r\n    /* background-size: cover;\r\n    background-repeat: no-repeat; */\r\n}", "",{"version":3,"sources":["webpack://./src/components/InspiringStories.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,uBAAuB;IACvB,oFAAoF;IACpF;mCAC+B;AACnC","sourcesContent":[".rackWiseModule {\r\n    width: 85% !important;\r\n    margin: 0 auto !important;\r\n    justify-content: center;\r\n    /* background-image: url('../resources/assets/EXPORTS/NOVEL/PNG/Asset 13@4x.png'); */\r\n    /* background-size: cover;\r\n    background-repeat: no-repeat; */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rackWiseModule": "InspiringStories_rackWiseModule__Nlwjv"
};
export default ___CSS_LOADER_EXPORT___;
