import axios from "axios";
import { Axios } from 'axios';
import React,{Component} from "react";
 import {IP} from '../connection';
class getEvent extends Component {
    static myInstance = null;
    
    static getInstance() { 
      
        return new getEvent();
            
    }
    
    registerService(data) {
        return new Promise((resolve,reject)=>{
            var newData =JSON.stringify(data);
          axios.post(IP+'register',data)
          .then(function (response) {
            //   //console.log(response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            //console.log(error);
          });

        })         
    }

    loginService(data){
      // Axios.defaults.withCredentials=true 
        return new Promise((resolve,reject)=>{
            var newData=JSON.stringify(data);
            axios.post(IP+'login',data)
            .then(function (response) {
              //   //console.log(response.data);
              resolve(response.data);
            })
            .catch(function (error) {
              //console.log(error);
            });
  
          })         
    }
    getAllLanguage(data) {
      return new Promise((resolve,reject)=>{
        axios.post(IP+'lang/getLang',data)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          //console.log(error);
        });

      })         }

      getGrade() {
        return new Promise((resolve,reject)=>{
          axios.post(IP+'game/getGrade')
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            //console.log(error);
          });
  
        })         }

        getUserCount() {
          return new Promise((resolve,reject)=>{
            axios.get(IP+'visits')
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              //console.log(error);
            });
    
          })         
        
        }
	postUserCount() {
          return new Promise((resolve,reject)=>{
            axios.post(IP+'visits')
            .then(function (response) {
              // alert(JSON.stringify(response.data))
              resolve(response.data);
            })
            .catch(function (error) {
              //console.log(error);
            });

          })

        }

        getUserStats(){
          return new Promise ((resolve, reject)=>{
            axios.get(IP+'getCounters').then(function (response) {
              // console.log(response)
              resolve(response.data);
            }).catch(function (error) {
              console.log(error);
            });
          })
        }
// incrementCounter

    postUserStatCount(data){
      return new Promise ((resolve, reject)=>{
        axios.post(IP+'incrementCounter',data).then(function (response) {
          resolve(response.data);
        }).catch(function (error) {
          console.log(error);
        });
      })
    }

}
    export default getEvent
