import React, { useState, useEffect } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import l from '../resources/assets/l.png';
import e from '../resources/assets/e.png';
import t from '../resources/assets/t.png';
import Icon1 from '../resources/assets/assets/lf1.png';
import Icon2 from '../resources/assets/assets/lf2.png';
import Icon3 from '../resources/assets/assets/lf3.png';
import Icon4 from '../resources/assets/assets/lf4.png';
import visitorsBG from '../resources/assets/EXPORTS/HOME PAGE/WEBP/Asset 6@4x.webp';
import Container from 'react-bootstrap/Container';
import UserService from '../Services/userService';

function Footer() {
  const [userCount, setUserCount] = React.useState('Loading..');

  React.useEffect(() => {
    getUserCount();
  }, []);

  const getUserCount = async () => {
    await UserService.getInstance().getUserCount().then((json) => {
      if (json.status === 200) {
        setUserCount(json.data);
        console.log(json.data);
      } else {
        setUserCount(100000);
      }
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const desktopStyle = {
    display: 'block',
    textAlign: 'center',
  };

  const mobileStyle = {
    display: 'block',
    textAlign: 'center',
  };

  const mobileSpanStyle = {
    display: 'block',
    margin: 0,
  };

  const desktopSpanStyle = {
    display: 'none',
  };

  return (
    <footer style={{ backgroundColor: 'transparent', padding: '20px 0', textAlign: 'center' }}>
      <Container>
        <Grid container justifyContent={'center'} alignItems="center" spacing={2} style={{ textAlign: 'center' }}>
          {/* Visitors Count */}
          <Grid item xs={12} md={2}>
            <div className="VisitorsMainDiv" style={{ position: 'relative', textAlign: 'center', color: 'white', maxWidth: '100%', margin: '0 auto' }}>
              <img style={{ width: isMobile ? '60%' : '100%', height: 'auto' }} src={visitorsBG} alt="Visitors Count" />
              <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                <Typography style={{
                  fontSize: '1rem',
                  color: '#0054A5',
                  fontWeight: 500,
                  marginBottom: '4px',
                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.8)'
                }}>
                  Number of Visitors
                </Typography>
                <h4 style={{
                  fontSize: '1rem',
                  color: '#0054A5',
                  fontWeight: 500,
                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.8)'
                }}>
                  {userCount}
                </h4>
              </div>
            </div>
          </Grid>

          {/* Social Media Icons */}
          <Grid item xs={12} md={3} style={{ textAlign: 'center', marginTop: '16px' }}>
            <div className="iconsGroup" style={{ display: 'flex', justifyContent: 'center', padding: '10px', gap: '10px' }}>
              <Link href="https://www.facebook.com/ncertofficial/">
                <img className="FooterSocialIcons" style={{ maxWidth: '60%', height: 'auto' }} src={Icon1} alt="Facebook Logo" />
              </Link>
              <Link href="https://twitter.com/ncert">
                <img className="FooterSocialIcons" style={{ maxWidth: '60%', height: 'auto' }} src={Icon2} alt="Twitter Logo" />
              </Link>
              <Link href="https://www.youtube.com/channel/UCT0s92hGjqLX6p7qY9BBrSA">
                <img className="FooterSocialIcons" style={{ maxWidth: '60%', height: 'auto' }} src={Icon3} alt="YouTube Logo" />
              </Link>
              <Link href="https://www.instagram.com/ncertindia/">
                <img className="FooterSocialIcons" style={{ maxWidth: '60%', height: 'auto' }} src={Icon4} alt="Instagram Logo" />
              </Link>
            </div>
          </Grid>

          {/* Contact Information and Helpline Numbers */}
          <Grid container item xs={12} md={7} spacing={2} alignItems="center">
            {/* Contact Information */}
            <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
              <div style={{ margin: '5px 0', fontSize: '1rem', color: '#fff', display: 'flex', alignItems: 'center' }}>
                <img style={{ marginRight: 8, width: 20, height: 20 }} src={l} alt="Location" />
                <span>NCERT, New Delhi-110016</span>
              </div>
              <div style={{ margin: '5px 0', fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
                <img style={{ marginRight: 8, width: 20, height: 20 }} src={e} alt="Email" />
                <a style={{ textDecoration: 'none', color: '#fff' }} href="mailto:dceta.ncert@nic.in">dceta.ncert@nic.in</a>
              </div>
            </Grid>

            {/* Helpline Numbers */}
            <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
              <div style={{ margin: '5px 0', fontSize: '1rem', color: '#fff', display: 'flex', alignItems: 'center' }}>
                <img style={{ marginRight: 8, width: 20, height: 20 }} src={t} alt="Telephone" />
                <span>Women Helpline Number : 7827170170</span>
              </div>
              <div style={{ margin: '5px 0', fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
                <img style={{ marginRight: 8, width: 20, height: 20 }} src={t} alt="Telephone" />
                <span>Cybercrime Reporting Number : 1930</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/* Copyright Text */}
      <div style={{ margin: '0px auto' }}>
        <p
          className="FooterCopyrights"
          variant="body2"
          style={isMobile ? mobileStyle : desktopStyle}
        >
          {isMobile ? (
            <>
              <p style={mobileSpanStyle}>
                Copyright &copy; {new Date().getFullYear()} |
              </p>
              <p style={mobileSpanStyle}>
                Designed and Developed by <a className='FooterCopyrightsA' href='https://ncert.nic.in/' target='_blank' rel='noopener noreferrer'>CIET NCERT</a>.
              </p>
            </>
          ) : (
            <>
              Copyright &copy; {new Date().getFullYear()} | Designed and Developed by <a className='FooterCopyrightsA' href='https://ncert.nic.in/' target='_blank' rel='noopener noreferrer'>CIET NCERT</a>.
            </>
          )}
        </p>
      </div>

      {/* Inline CSS for Mobile Responsiveness */}
      <style jsx>{`
        .VisitorsMainDiv {
          position: relative;
          text-align: center;
          color: white;
          max-width: 100%;
          margin: 0 auto;
        }
        .FooterSocialIcons {
          max-width: 60%;
          height: auto;
        }
        .FooterCopyrights {
          font-size: 1.1rem;
          text-align: center;
          background-color: transparent;
          padding: 8px 0;
          color: rgb(255,255,255);
          margin: 0;
        }
        .FooterCopyrightsA {
          text-decoration: none;
          color: #fff;
          font-weight: bold;
        }
        .iconsGroup {
          justify-content: space-between;
        }
        .contactInfo, .helplineNumbers {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 900px) {
          .VisitorsMainDiv {
            max-width: 50%;
          }
          .FooterSocialIcons {
            max-width: 40%;
          }
        }
        @media (max-width: 600px) {
          .footerContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .VisitorsMainDiv {
            max-width: 80%;
          }
          .iconsGroup {
            justify-content: center;
            margin-top: 16px;
            gap: 20px;
          }
          .contactInfo, .helplineNumbers {
            text-align: center;
            margin: 10px 0;
          }
        }
      `}</style>
    </footer>
  );
}

export default Footer;
