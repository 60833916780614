// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../resources/assets/Fonts/Rubik-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\r\n    font-family: 'Gabriola';\r\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\r\n    /* Add additional font formats if necessary */\r\n    /* Specify the correct path to the font files */\r\n  }\r\n  .mainDiv div, .mainDiv h3, .mainDiv h2{\r\n    font-family: 'Rubik-Regular', sans-serif;\r\n}", "",{"version":3,"sources":["webpack://./src/screens/UserStats.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,+DAA0E;IAC1E,6CAA6C;IAC7C,+CAA+C;EACjD;EACA;IACE,wCAAwC;AAC5C","sourcesContent":["@font-face {\r\n    font-family: 'Gabriola';\r\n    src: url('../resources/assets/Fonts/Rubik-Regular.ttf') format('truetype');\r\n    /* Add additional font formats if necessary */\r\n    /* Specify the correct path to the font files */\r\n  }\r\n  .mainDiv div, .mainDiv h3, .mainDiv h2{\r\n    font-family: 'Rubik-Regular', sans-serif;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
