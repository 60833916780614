import React, { useEffect, useState, useRef } from "react";
import SmallSquare from "./SmallSquare";
import "./BigSquare.css";
import DropSquare from "./dropSquare";
import { Button, Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import {
  useLocation,
} from "react-router";
import { IP } from '../../connection';
import Header from "../../screens/Header";
import Footer from "../../screens/Footer";
import Container from 'react-bootstrap/Container';
import Boy from '../../resources/assets/Boy.png'
import { Grid, Paper } from '@mui/material';
import chLogo from '../../resources/assets/chlogo.png';
import chimageicons from '../../resources/assets/assets/i4.png';
import BackButton from "../backButton";
import { useNavigate } from 'react-router-dom';
import "../puzzlegame/Carousel.css";
import TopHeader from "../TopHeader";


function shuffle(array) {
  return [...array].sort(() => Math.random() - 0.5);
}

const squares = Array.from({ length: 16 }, (_, i) => i);

function BigSquare(props) {
  const location = useLocation();
  const [randomSquares, setRandmoSquares] = useState([]);
  const [imageStatus, setImageStatus] = useState(false);
  var imgindex = useRef(0);
  // const imgUrl = useRef(IP+"getImage/?imgName=" + props.gameAttr.game_attr[0].options[0]);
  const [imgUrl, setImgUrl] = useState(props.gameAttr.game_attr[0].options)
  // //console.log(imgUrl);
  // const boxRef = useRef(null);
  const [userData, setUserData] = useState([]);
  const [imgDesc, setImgDesc] = useState('');
  const history = useNavigate();
  // const forceUpdate = React.useCallback(() => updateState({}), []);
  // const [imgindex,setImgImdex]=useState(0);

  useEffect(async () => {

    if (imgDesc.length === 0) { await setDesc() };

  });

  const setDesc = async () => {
    var data = props.gameAttr.game_attr[0].feedback[imgindex.current];
    data = data.split('/n');
    // //console.log(data);
    if (data.length > 0) {
      if (userData.lang_id === 2) {
        setImgDesc(data[1])
      }
      else {
        setImgDesc(data[0])
      }
    }
    else {
      setImgDesc(props.gameAttr.game_attr[0].feedback[imgindex.current]);
    }
    // if(userData.lang_id
  }


  useEffect(() => {
    // //console.log("asdsadasd");
    setRandmoSquares(() => shuffle(squares))
  }, [imgindex.current]);

  const widthSize = useRef(-1);
  useEffect(async () => {
    async function adjustBoxHeights() {
      // Get all elements with class 'square-box'
      const boxes = document.querySelectorAll('.Container .Container-Subs');
      // //console.log(boxes);
      // Loop through the boxes and set their height equal to their width
      boxes.forEach(async (box) => {
        const width = box.clientWidth;
        widthSize.current = width * 4;
        box.style.height = width + 'px';
        box.style.width = width + 'px';
        //console.log(width);
      });
    }

    //  await adjustBoxHeights();
    window.addEventListener('load', await adjustBoxHeights());
    window.addEventListener('resize', await adjustBoxHeights());

    // Remove the event listener when the component unmounts
    return async () => {
      window.removeEventListener('resize', await adjustBoxHeights());
    };
  }, [imgindex.current]);



  const handleImageChange = async () => {
    // //console.log(imgindex.current);
    if (imgindex.current < props.gameAttr.game_attr[0].options.length - 1) {
      imgindex.current = imgindex.current + 1;
      // setImgUrl(IP+"getImage/?imgName=" + props.gameAttr.game_attr[0].options[imgindex.current]);
      // setImgImdex(imgindex + 1);
      await setDesc();
    }
    // //console.log(imgindex.current);
    // //console.log(imgUrl);
  }
  function moveSquare(val) {
    let zeroIndex = randomSquares.indexOf(0);
    let valIndex = randomSquares.indexOf(val);

    if (valIndex + 4 === zeroIndex || valIndex - 4 === zeroIndex) {
      swap(valIndex, zeroIndex);
    } else if (valIndex + 1 === zeroIndex && zeroIndex % 4 !== 0) {
      swap(valIndex, zeroIndex);
    } else if (valIndex - 1 === zeroIndex && (zeroIndex + 1) % 4 !== 0) {
      swap(valIndex, zeroIndex)
    }
  }

  function swap(valIndex, zeroIndex) {
    let temArray = [...randomSquares]
    temArray[zeroIndex] = randomSquares[valIndex];
    temArray[valIndex] = 0;
    setRandmoSquares(() => [...temArray])
  }
  function isSolved(tiles) {
    for (let i = 0, l = tiles.length; i < l; i++) {
      if (tiles[i] !== i) {
        return false;
      }
    }
    return true;

  }
  const handleImageClick = () => {
    setImageStatus(!imageStatus);
  }
  const slides = props.gameAttr.game_attr[0].options;
  // const [slide, setSlide] = useState(0);
  const setSlide = async (idx) => {
    imgindex.current = idx;

    await setDesc();
  }

  const nextSlide = () => {
    imgindex.current = imgindex.current === props.gameAttr.game_attr[0].options.length - 1 ? 0 : imgindex.current + 1;

    setImgUrl(IP + "getImage/?imgName=" + props.gameAttr.game_attr[0].options[imgindex.current]);
    setDesc();
  };

  const prevSlide = () => {
    imgindex.current = imgindex.current === 0 ? props.gameAttr.game_attr[0].options.length - 1 : imgindex.current - 1;

    setImgUrl(IP + "getImage/?imgName=" + props.gameAttr.game_attr[0].options[imgindex.current]);
    setDesc();
  };



  const hasWon = isSolved(randomSquares)
  return (
    <>
      <TopHeader to="/activities" />
      {/* <div className="chLogoTopDiv">
        <div className="chLogoTopHeader" >
          <img className='chimgtopHeader' src={chLogo} alt="Logo 2" />
          <h2>Picture Builder</h2>
          {
            userData.lang_id === 2 ?
              <h2>चित्र निर्माता</h2>
              :
              <h2>Picture Builder</h2>
          }
        </div>
      </div>
      <BackButton /> */}
      <div style={{ margin: '2% auto 0' }}>
        <button
          style={{
            backgroundColor: '#0054A5',
            boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
            color: '#fff',
            padding: '0.5rem 2.3rem',
            border: 'none',
            borderRadius: '0.6rem',
            fontWeight: '700',
            fontSize: '1.3rem',
            margin: '0 auto 1.7rem',
          }}

          disabled
        >
          {userData.lang_id === 2 ? 'चित्र निर्माता' : 'PICTURE BUILDER'}
        </button>
      </div>

      {
        imageStatus &&
        <div style={{ zIndex: 9999 }}>
          <Modal
            show={true}
            onHide={() => handleImageClick()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {/* Artifact Collection */}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{textAlign: 'center'}}>
                {/* <h3>{IP+"getImage/?imgName=" +imgUrl}</h3> */}
                <img style={{ width: "55%" }} src={IP + "getImage/?imgName=" + imgUrl[imgindex.current]}></img>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => handleImageClick()}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>
      }

      <div style={{ margin: '2% 0 2%' }}>
        <Container fluid style={{ textTransform: 'none', textAlign: 'center' }}>
          <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>

            <Grid item xs={0} sm={0} md={0} xl={1} lg={0} style={{}}>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={7} lg={7.8} style={{ padding: 0 }}>
              <span className="indicators">
                {/* <button onClick={prevSlide} className="arrow arrow-left">prev</button> */}
                {slides.map((item, idx) => {
                  return (
                    <>
                      <div className="slidePaginationsDiv">
                        <img
                          draggable="false"
                          src={IP + "getImage/?imgName=" + item}
                          alt="images"
                          key={idx}
                          className={imgindex.current === idx ? "slidePaginations" : "slidePaginations slidePaginations-hidden"}
                          onClick={() => setSlide(idx)}
                        />

                      </div>
                    </>
                  );
                })}
                {/* <button onClick={nextSlide} className="arrow arrow-right">next</button> */}

              </span>
              <div>
                <div className="mainBoxDiv" style={{}}>
                  <div className="Container 1">
                    <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>


                      {randomSquares.map((e, i) => {
                        return (
                          <Grid item xs={3} sm={3} md={3} xl={3} lg={3} style={{ padding: '0px', margin: 0 }}>
                            <div key={e} className="Container-Subs">
                              <SmallSquare value={e} imgUrl={imgUrl} imageIndex={imgindex.current} widthSize={widthSize.current} clickHandler={moveSquare} />
                            </div>
                          </Grid>

                        );
                      })}
                    </Grid>
                  </div>
                  <div className="Container 2">
                    <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
                      {squares.map((e, i) => {
                        return (
                          <Grid item xs={3} sm={3} md={3} xl={3} lg={3} style={{ padding: '0px', margin: 0 }}>
                            <div key={e} className="Container-Subs" >
                              <DropSquare value={e} imageIndex={imgindex.current} clickHandler={moveSquare} />
                            </div>
                          </Grid>

                        );
                      })}
                    </Grid>
                  </div>

                </div>
                <div style={{ display: 'flex', width: '95%', justifyContent: 'center', margin: '0 auto' }}>
                  <Grid item xs={8} sm={8} md={9} xl={9} lg={9} style={{ padding: '0px', margin: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {/* <button onClick={() => handleImageClick()} style={{width: '40%', borderRadius: "10px", padding: "5px", border: "none", backgroundColor: "rgba(14, 136, 211, 1)", color: "white", margin: '10px 10px 0px' }}><span>Original Image</span></button> */}
                      {
                        userData.lang_id === 2 ?
                          <button onClick={() => handleImageClick()} style={{
                            borderRadius: "10px",
                            padding: "2% 3%",
                            border: "none",
                            backgroundColor: '#0054A5',
                            boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                            color: "white",
                            margin: '20px auto 0px',
                            cursor: 'pointer'
                          }}><span>वास्तविक प्रतिबिंब</span></button>
                          :
                          <button onClick={() => handleImageClick()} style={{
                            borderRadius: "10px",
                            padding: "2% 3%",
                            border: "none",
                            backgroundColor: '#0054A5',
                            boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                            color: "white",
                            margin: '20px auto 0px',
                            cursor: 'pointer'
                          }}>
                            <span>Original Image</span>
                          </button>

                      }

                        {/* <span onClick={handleImageChange} style={{borderRadius: "10px", padding: "5px 15px", border: "none", backgroundColor: "rgb(155, 35, 76)", color: "white", margin: '0 5px', cursor: 'pointer'}}>Change Image</span> */}
                        {
                          userData.lang_id === 2 ?
                            <span onClick={handleImageChange} style={{
                              borderRadius: "10px",
                              padding: "2% 3%",
                              border: "none",
                              backgroundColor: '#0054A5',
                              boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                              color: "white",
                              margin: '20px auto 0px',
                              cursor: 'pointer'
                            }}>चित्र को बदलें</span>
                            :
                            <span onClick={handleImageChange} style={{
                              borderRadius: "10px",
                              padding: "2% 3%",
                              border: "none",
                              backgroundColor: '#0054A5',
                              boxShadow: 'rgb(255, 255, 255) 0px 0px 10px 6px',
                              color: "white",
                              margin: '20px auto 0px',
                              cursor: 'pointer'
                            }}>Change Image</span>
                        }
                    </div>
                  </Grid>

                </div>
              </div>
            </Grid>
            {userData.lang_id === 2 ?

              <Grid item xs={12} sm={12} md={12} xl={3} lg={4} style={{ margin: '10px auto', textAlign: 'left', color: '#000' }} className="jgliDiv" >
                <div style={{ backgroundColor: '#fff', borderRadius: '15px', padding: '20px', boxShadow: '5px 5px 10px rgba(0,0,0,0.2)', margin: '5% auto', width: '80%' }}>

                  <h4>पहेली कैसे सुलझाएँ?</h4>
                  <ul>

                    <li>इस पहेली को पूरा करने के लिए टाइलों को बाएँ ग्रिड से दाएँ ग्रिड पर पुनर्व्यवस्थित करें।</li>
                    <li>यदि आप सहायता चाहते हैं, तो आप पहेली के नीचे दिए गए ‘वास्तविक प्रतिबिम्ब’ बटन पर क्लिक कर सकते हैं।</li>
                    <li>ध्यान रखें, एक समय में आप केवल एक ही टाइल को बाएँ ग्रिड से दाएँ ग्रिड पर पुनर्व्यवस्थित कर सकते हैं। </li>
                  </ul>

                </div>

                <div className="" style={{ backgroundColor: '#fff', borderRadius: '15px', padding: '20px', boxShadow: '5px 5px 10px rgba(0,0,0,0.2)', margin: '5% auto', width: '80%' }}>
                  <h4>चित्र के बारे में</h4>
                  <ul>
                    <li>{imgDesc}</li>

                  </ul>
                </div>

              </Grid>

              :
              <Grid item xs={12} sm={12} md={12} xl={3} lg={4} style={{ margin: '10px auto', textAlign: 'left', color: '#000' }} className="jgliDiv" >
                <div style={{ backgroundColor: 'rgba(255,255,255,0.7)', borderRadius: '15px', padding: '20px', boxShadow: '5px 5px 10px rgba(0,0,0,0.2)', margin: '5% auto', width: '80%' }}>

                  <h4>How to solve the puzzle?</h4>
                  <ul>

                    <li>Rearrange the jumbled picture tiles to recreate the picture.</li>
                    <li>Remember, you can move only one tile at a time.</li>
                    <li>If you want help, you can click on the Original Image Button below the puzzle.</li>
                  </ul>
                </div>

                <div className="" style={{ backgroundColor: 'rgba(255,255,255,0.7)', borderRadius: '15px', padding: '20px', boxShadow: '5px 5px 10px rgba(0,0,0,0.2)', margin: '5% auto', width: '80%' }}>
                  <h4>About the Picture</h4>
                  <ul>
                    <li>{imgDesc}</li>

                  </ul>
                </div>

              </Grid>
            }
          </Grid>
        </Container>
      </div>

      {/* {hasWon && <div>Puzzle solved 🧠 🎉</div>} */}
      <Footer />
    </>
  );
}

export default BigSquare;